@import "../../styles/variables.scss";

.tv-toggleSwitch {
	position: relative;
	display: inline-block;

	&.tv-toggleSwitch__rounded {
		.Slider {
			border-radius: 15rem;
			background-color: $TEXT_INACTIVE_COLOR;
			&:before {
				border-radius: 50%;
			}
		}
	}

	.tv-toggleSwitch__wrapper {
		position: relative;
		width: 60px;
	  height: 30px;

		.Slider {
			position: absolute;
			cursor: pointer;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			transition: .4s ease;

			&:before {
				width: 24px;
				height: 24px;
				position: absolute;
        background-color: $WHITE_COLOR;
				content: '';
				margin: 0;
				padding: 0;
				top: 50%;
				left: 0.2rem;
				transform: translateY(-50%);
				transition: .4s;
				cursor: pointer;
				box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
			}

			&.isChecked {
				background: $SECONDARY_COLOR;

				&:before {
					left: calc(100% - 1.7rem);
					background: $WHITE_COLOR;
				}
			}
		}
	}
}