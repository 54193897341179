@import "../../../Share/styles/variables.scss";

.tv-block-wrapper {
  display: flex;
  flex-direction: column;
  width: 360px;
  box-shadow: 0 0 13px 0 rgba(223,223,223,0.5);

  &__title {
    color: $NAV_BAR_TEXT_COLOR;
    font-family: "PT Serif";
    font-size: 14px;
    line-height: 24px;
    padding: 14px 24px;
    background-color: $TITLE_COLOR;
  }
}