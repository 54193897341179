.tv-workassignment {
  &__wrapper {
    margin: 50px 0 0 0;
  }
  &__title {
    color: #353535;
    font-weight: 500;
    font: 1.5rem/2rem $PRIMARY_FONT;
    margin: 0 0 20px 0;
  }
  &__control-panel {
    margin: 0 0 20px 0;

    &.h-pdl--30 {
      // position: absolute;
      // right: 56px;
      padding-left: 30px;
    }
    .h-fs--16 {
      font-size: 16px;
    }
  }
  &__list {
    &-item {
      @extend .tv-border-radius-shadow;
      cursor: pointer;
      margin: 16px 0 0 0;
      padding: 18px 0;
      font-family: $SECONDARY_FONT;
      z-index: 10;

      &-child {
        margin: 0 13px;
        box-shadow: inset 0 10px 18px -12px rgba(58, 68, 131, 0.2), 0 10px 18px -12px rgba(58, 68, 131, 0.2);
      }

      // &-parent {
      //   position: relative;
      // }
    }
  }
  &__item {
    &-status {
      width: 16.6%;
      align-items: center;
      justify-content: center;
    }
    &-order {
      width: 10%;
    }
    &-name {
      width: 29.1%;
    }
    &-category {
      width: 16.7%;
    }
    &-datetime {
      width: 27.6%;
    }
    &-type {
      width: 12%;
    }
    &-menu {
      width: 7%;
      align-items: center;
      justify-content: center;

      &-button {
        &:before {
          @include tv-transvoice-font;
          font-size: 1.5rem;
        }
      }
    }
    &-label {
      font-size: 0.875rem;
      color: $DARK_GRAY_COLOR;
      margin: 0 0 11px 0;
    }
    &-text {
      color: $NAV_BAR_TEXT_COLOR;

      &-available-until {
        font-weight: bold;
        color: $SECONDARY_COLOR;
      }
    }
  }
  &__status {
    font: 0.625rem $PRIMARY_FONT;
    color: $WHITE_COLOR;
    border-radius: 20px;
    text-transform: uppercase;
    padding: 4px 10px 3px 10px;

    &--available {
      background: $COLOR_STATUS_AVAILABLE;
    }
    &--accepted {
      background: $COLOR_STATUS_ACCEPTED;
    }
    &--rejected {
      background: $COLOR_STATUS_REJECTED;
    }
    &--losted {
      background: $COLOR_STATUS_LOSTED;
    }
    &--traveling,
    &--working {
      background: $COLOR_STATUS_WORKING;
      color: black;
    }
    &--fullfilled {
      background: $COLOR_STATUS_FULLILLED;
      color: black;
    }
    &--done {
      background: $COLOR_STATUS_DONE;
      color: black;
    }
    &--cancelled {
      background: $COLOR_STATUS_CANCELLED;
    }
    &--submitted {
      background: $COLOR_STATUS_SUBMITTED;
    }

    &--accepted,
    &--submitted {
      &:before {
        content: "\e939";
        @include tv-transvoice-font;
        color: $WHITE_COLOR;
        margin: 0 6px 0 0;
      }
    }
  }

  &__detail {
    &-wrapper {
      font-family: $SECONDARY_FONT;
    }
    &-status {
      font-size: 0.875rem;
      color: $DARK_GRAY_COLOR;
      margin: 36px 0 24px 32px;
      .tv-workassignment__status {
        margin-right: 8px;
      }
    }
    &-orderno {
      color: #454c98;
    }
    &-name {
      font: 1.5rem/2.1875rem $PRIMARY_FONT;
      font-weight: 300;
      color: $DARK_GRAY_COLOR;
      color: #353535;
      margin: 0 32px;
    }
    &-datetime {
      font-size: 0.875rem;
      color: $SECONDARY_COLOR;
      margin: 6px 0 24px 32px;
      .icon-calendar {
        margin-right: 14px;
      }
    }
    &-title {
      font: 1rem $PRIMARY_FONT;
      color: $NAV_BAR_TEXT_COLOR;
      background-color: #f9f9f9;
      padding: 14px 0 13px 32px;
    }
    &-description {
      margin: 16px 32px 0px 32px;
      font: rem-calc(14) / rem-calc(24) $SECONDARY_FONT;
      color: $NAV_BAR_TEXT_COLOR;
    }
    &-buttons {
      margin: 36px 32px;
      justify-content: space-between;
      .tv-buttons__element {
        padding: 13px 0 12px;
        width: 47.04%;

        &-link {
          width: 47.04%;

          button {
            width: 100%;
          }
        }

        &--report {
          padding: 13px 24px 12px;
          width: unset;
        }
      }
    }
  }

  &__attach {
    &-title {
      padding: 14px 0 13px 32px;
      color: #a9a9a9;
      font-family: "PT Serif";
      font-size: 14px;
      letter-spacing: 0.3px;
      line-height: 20px;
    }

    &-list {
      padding: 0px 0 17px 32px;

      .tv-attachment {
        margin-right: 8px;
        margin-bottom: 8px;
        background-color: transparent;
      }
    }
  }

  &__info {
    &-wrapper {
      margin: 17px 0 17px 32px;
      padding: 0;
    }
    &-item {
      list-style: none;
      margin: 7px 0;
      font-size: 0.875rem;
      color: $NAV_BAR_TEXT_COLOR;
      &:before {
        margin-right: 15px;
      }
      a {
        color: $SECONDARY_COLOR;
      }
      &.icon-none-icon:before {
        content: "";
        margin-right: 30px;
      }
    }
    &-agree {
      margin: 17px 32px 0 32px;
      color: $NAV_BAR_TEXT_COLOR;
      font: italic rem-calc(14) / rem-calc(24) $SECONDARY_FONT;

      &-terms {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  &__drawer {
    &-wrapper {
    }
    &-overlay {
      content: "";
      bottom: 0;
      left: 0;
      position: fixed;
      right: 0;
      top: 0;
      background: rgba(0, 0, 0, 0.3);
      z-index: 16;
    }
    &-holder {
      width: 468px;
      background: $WHITE_COLOR;
      position: fixed;
      height: 100vh;
      overflow-y: auto;
      top: 0;
      right: 0;
      z-index: 20;
      box-shadow: -5px 0 15px 0 rgba(137, 136, 150, 0.27);
      transition: all 0.5s ease;
      transform: translateX(100%);

      .js-slide-in & {
        transform: translateX(0%);
      }
     
    }
    &-close {
      position: absolute;
      top: 10px;
      right: 10px;

      &:before {
        color: $NAV_BAR_TEXT_COLOR;
        font-size: 1.2rem;
      }
    }
  }
  &__drawer2 {
    &-wrapper2{
    }
    &-overlay2 {
      content: "";
      bottom: 0;
      left: 0;
      position: fixed;
      right: 0;
      top: 0;
      background: rgba(0, 0, 0, 0.3);
      z-index: 5;
    }
    &-holder2 {
      width: 560px;
      background: $WHITE_COLOR;
      position: fixed;
      height: 100vh;
      overflow-y: auto;
      top: 0;
      right: 0;
      z-index: 15;
      box-shadow: -5px 0 15px 0 rgba(137, 136, 150, 0.27);
      transition: all 0.5s ease;
      transform: translateX(100%);

     
      .js-slide-in2 & {
        transform: translateX(0%);
      }
    }
    &-close2 {
      position: absolute;
      top: 10px;
      right: 10px;

      &:before {
        color: $NAV_BAR_TEXT_COLOR;
        font-size: 1.2rem;
      }
    }
  }
  &__filter-date-range {
    padding-right: 30px;
    position: relative;

    &__content {
      position: absolute;
      box-shadow: 1px 1px 1px 1px $TEXT_INACTIVE_COLOR;
      background: $WHITE_COLOR;
      display: flex;
      flex-direction: column;

      .tv-buttons__element {
        width: 30%;
        margin: 20px auto;
      }
    }
  }
}
