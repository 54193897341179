.tv-left-pannel {
  &__wrapper {
    flex: 1;
    max-width: $pannelLeftWidth;
    background-color: $WHITE_COLOR;
    box-shadow: 0 0 13px 0 rgba(223, 223, 223, 0.5);
    z-index: 10; // For left pannel higher than right pannel so box-shadow can work
  }

  &__logo {
    width: 100%;
    height: 99px;
    padding-top: 25px;
    font-family: $SECONDARY_FONT;
    letter-spacing: -1px;

    display: flex;
    justify-content: center;
    cursor: pointer;
    a {
      text-decoration: none;
    }

    img {
      width: 120px;
    }
  }

  &__sidebar {
    a {
      text-decoration: none;
    }
    .tv-icon-fixed-width {
      display: inline-block;
      width: 24px;
      text-align: center;
    }
  }

  &__sidebar-item {
    display: flex;
    padding: 20px 10px 20px 20px;
    cursor: pointer;
    color: $NAV_BAR_TEXT_COLOR;
    font: 0.875rem/1.5rem $PRIMARY_FONT;
    font-weight: 100;
    border-left: 2px solid transparent;

    &:hover,
    &.active {
      background-color: $NAV_BAR_HOVER_COLOR;
      border-left: 2px solid $PRIMARY_COLOR;
      font-weight: bold;
      color: $PRIMARY_COLOR;
      i:before {
        color: $PRIMARY_COLOR;
      }
    }
  }

  &__sidebar-icon {
    font-size: 21px;
    margin-right: 10px;
  }
}

.tv-right-pannel-wrapper {
  flex: 4.76;
  min-height: 100vh;
  background-color: $BACKGROUND_COLOR;
  overflow-y: scroll;

  .tv-right-status {
    width: 100%;
    height: 64px;
    background-color: $WHITE_COLOR;
    padding: 14px 98px 14px 40px;

    .tv-user-info {
      display: flex;
      align-items: center;

      .tv-avatar {
        display: inline;
        position: relative;
        cursor: pointer;

        &__img {
          border-radius: 50%;
          width: 36px;
          height: 36px;
          object-fit: cover;
        }
      } // end tv-avatar

      .tv-notification {
        display: inline;
        margin-left: 30px;
        position: relative;
        cursor: pointer;

        &__icon {
          vertical-align: middle;
          font-size: 1.4rem;
          color: #c5c5c5;
        }

        &__circle {
          width: 11px;
          height: 11px;
          border-radius: 50%;
          background-color: $PRIMARY_COLOR;
          display: inline-block;
          position: absolute;
          top: -2px;
          left: 10px;
        }
      } // end tv-notification

      .tv-search {
        margin-left: auto;

        .form-inline {
          display: flex;
          align-items: center;
          i {
            margin-right: 15px;
            font-size: 20px;
            cursor: pointer;
          }

          input {
            width: 16rem;
            border: none;
            font-family: "PT Serif";
            font-size: 1rem;
            color: $NAV_BAR_TEXT_COLOR;
          }
        }
      } // end .tv-search
    } // end user-info
  } // end right-status

  .tv-right-content {
    padding-top: 0;
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 60px;

    // max-width: 1130px;
    margin-left: auto;
    margin-right: auto;
  } // end tv-right-content
} // end right-pannel-wrapper