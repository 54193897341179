.tv-radialgauge {
  &-container {
    position: relative;
  }
  &-inner_detail {
    position: absolute;
    display: flex;
    flex-direction: column;
    text-align: center;
    bottom: 10px;
    left: 0px;
    width: 100%;
  }
}
