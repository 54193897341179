.tv-youravailability {
  &__wrapper {
    margin-top: 32px;
  }
  &__title {
    @extend .tv-workassignment__title;
  }

  &__list {
    flex-wrap: wrap;
    &-drawer{
      margin-top: 10%;
      margin-left: 2%;
    }
    &-item {
      position: relative;
      @extend .tv-border-radius-shadow;
      cursor: default;
      margin: 0 1.5% 16px 0;
      font-family: $SECONDARY_FONT;
      width: 31%;
      box-shadow: 0 0 13px 0 rgba(223,223,223,0.5);
      &-drawer{
         position: relative;
      @extend .tv-border-radius-shadow;
      cursor: default;
      margin: 0 1.5% 16px 0;
      font-family: $SECONDARY_FONT;
        width: 90%;
        box-shadow: 0 0 13px 0 rgba(223,223,223,0.5);
        
      }
    }
  }

  &__container {
    padding: 24px;
    width: 100%;
  }

  &__item {
    &-hr {
      border: 0.5px solid rgba(223,223,223,0.5);
    }

    &-space {
      padding-left: 5px; 
    }

    &-label {
      color: $DARK_GRAY_COLOR;
      font: rem-calc(14)/rem-calc(24);
      letter-spacing: 0.3;
    }

    &-text {
      color: $NAV_BAR_TEXT_COLOR;
      font: rem-calc(14)/rem-calc(24) $SECONDARY_FONT;	
      letter-spacing: 0.3;

      &-datetime {
        color: #322878;	
        font: 300 rem-calc(18)/rem-calc(28) $PRIMARY_FONT;
        letter-spacing: -0.2px;
        min-height: 56px;
      }
    }

    &-menu {
      cursor: pointer;
      padding-top: 19px;
      position: absolute;
      top: 0;
      right: 10px;

      &-button {
        &:before {
          @include tv-transvoice-font;
          font-size: 1.5rem;
        }
      }
    }
  }

  &__dropdown {
    .tv-filter {
      &-list {
        &__menu {
          width: 90px;
          margin: 0;
          right: 0;
          box-shadow: 0 0 13px 0 rgba(223,223,223,0.5);
        }
      }
    }

    .tv-buttons {
      &__type {
        padding: 10px;
        text-align: left;
      }
    }
  }
}