/* Campton @font-face kit */
@font-face {
  font-family: "Campton";
  src: url("/fonts/Campton/Rene Bieder - Campton Medium.otf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Campton";
  src: url("/fonts/Campton/Rene Bieder - Campton Book.otf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "transvoice-icon";
  src: url("/fonts/transvoice-icon/transvoice-icon.eot?ysq9j9");
  src: url("/fonts/transvoice-icon/transvoice-icon.eot?ysq9j9#iefix") format("embedded-opentype"),
    url("/fonts/transvoice-icon/transvoice-icon.ttf?ysq9j9") format("truetype"),
    url("/fonts/transvoice-icon/transvoice-icon.woff?ysq9j9") format("woff"),
    url("/fonts/transvoice-icon/transvoice-icon.svg?ysq9j9#transvoice-icon") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "transvoice-icon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-filter:before {
  content: "\e900";
  color: #c5c5c5;
}
.icon-your-compensation:before {
  content: "\e901";
  color: #c5c5c5;
}
.icon-warning:before {
  content: "\e902";
  color: #c0c2c3;
}
.icon-waiting-to-be-submitted:before {
  content: "\e903";
  color: #fff;
}
.icon-trophy:before {
  content: "\e904";
  color: #bdbdbd;
}
.icon-timer:before {
  content: "\e905";
  color: #c5c5c5;
}
.icon-the-envelope:before {
  content: "\e906";
  color: #c5c5c5;
}
.icon-task:before {
  content: "\e907";
  color: #c5c5c5;
}
.icon-submit-time:before {
  content: "\e908";
  color: #c5c5c5;
}
.icon-small:before {
  content: "\e909";
  color: #bdbdbd;
}
.icon-share:before {
  content: "\e90a";
  color: #c5c5c5;
}
.icon-search:before {
  content: "\e90b";
}
.icon-schedule:before {
  content: "\e90c";
  color: #bdbdbd;
}
.icon-report-quality:before {
  content: "\e90d";
  color: #c5c5c5;
}
.icon-question:before {
  content: "\e90e";
  color: #c0c2c3;
}
.icon-profile:before {
  content: "\e90f";
  color: #c5c5c5;
}
.icon-pointer:before {
  content: "\e910";
  color: #c5c5c5;
}
.icon-photo:before {
  content: "\e911";
  color: #9e9e9e;
}
.icon-news:before {
  content: "\e912";
  color: #c5c5c5;
}
.icon-lock:before {
  content: "\e913";
  color: #c0c2c3;
}
.icon-interprete-now:before {
  content: "\e914";
  color: #c5c5c5;
}
.icon-interpret-now-2:before {
  content: "\e915";
  color: #fff;
}
.icon-user:before {
  content: "\e916";
  color: #bdbdbd;
}
.icon-hourglass:before {
  content: "\e917";
  color: #c9c9c9;
}
.icon-home:before {
  content: "\e918";
  color: #c5c5c5;
}
.icon-handset:before {
  content: "\e919";
  color: #c5c5c5;
}
.icon-handset-2:before {
  content: "\e91a";
  color: #c5c5c5;
}
.icon-flag:before {
  content: "\e91b";
  color: #bdbdbd;
}
.icon-fire:before {
  content: "\e91c";
  color: #fff;
}
.icon-drawing-pin:before {
  content: "\e91d";
  color: #bdbdbd;
}
.icon-download-to-cloud:before {
  content: "\e91e";
  color: #c0c2c3;
}
.icon-dollar:before {
  content: "\e91f";
  color: #fff;
}
.icon-dollar-2:before {
  content: "\e920";
  color: #d1d1d1;
}
.icon-create-new:before {
  content: "\e921";
  color: #fff;
}
.icon-contact:before {
  content: "\e922";
  color: #c5c5c5;
}
.icon-contact-2:before {
  content: "\e923";
  color: #c5c5c5;
}
.icon-comment:before {
  content: "\e924";
  color: #c0c2c3;
}
.icon-comment-2:before {
  content: "\e925";
  color: #fff;
}
.icon-close:before {
  content: "\e926";
  color: #313131;
}
.icon-certificate:before {
  content: "\e927";
  color: #bdbdbd;
}
.icon-camera:before {
  content: "\e928";
  color: #fa0a5f;
}
.icon-calendar:before {
  content: "\e929";
  color: #bdbdbd;
}
.icon-browse-assignments:before {
  content: "\e92a";
  color: #c5c5c5;
}
.icon-book:before {
  content: "\e92b";
  color: #c5c5c5;
}
.icon-book-2:before {
  content: "\e92c";
  color: #c0c2c3;
}
.icon-bell:before {
  content: "\e92d";
  color: #c5c5c5;
}
.icon-available-request:before {
  content: "\e92e";
  color: #c5c5c5;
}
.icon-available-request-2:before {
  content: "\e92f";
  color: #fff;
}
.icon-attach:before {
  content: "\e930";
  color: #9e9e9e;
}
.icon-arrow-more:before {
  content: "\e931";
  color: #c5c5c5;
}
.icon-arrow-more-2:before {
  content: "\e932";
  color: #c5c5c5;
}
.icon-arrow-expand:before {
  content: "\e933";
}
.icon-arrow-back:before {
  content: "\e934";
}
.icon-settings:before {
  content: "\e935";
  color: #c5c5c5;
}
.icon-envelope:before {
  content: "\e936";
  color: #c5c5c5;
}
.icon-log-out:before {
  content: "\e937";
  color: #fa0a5f;
}
.icon-more:before {
  content: "\e938";
  color: #c5c5c5;
}
.icon-check:before {
  content: "\e939";
  color: #c5c5c5;
}
.icon-doc:before {
  content: "\e93a";
  color: #c5c5c5;
}
.icon-pdf:before {
  content: "\e93b";
  color: #c5c5c5;
}
.icon-download-file:before {
  content: "\e93c";
  color: #c5c5c5;
}
.icon-upload-to-cloud:before {
  content: "\e93d";
  color: #c5c5c5;
}

.icon-single:before {
  content: "\e93e";
  color: #c5c5c5;
}

.icon-multi:before {
  content: "\e93f";
  color: #c5c5c5;
}

.icon-image-file:before {
  content: "\e940";
  color: #c5c5c5;
}

.icon-mobile-phone:before {
  content: "\e941";
  color: #c5c5c5;
}

.icon-customer-order-number:before {
  content: "\e942";
  color: #c5c5c5;
}