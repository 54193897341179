@import "../../../Share/styles/variables.scss";
@import "../../../Share/styles/mixin.scss";

.tv-right-status {
  .icon-search {
    color: $BLACK_COLOR;
  }

  a:hover {
    text-decoration: none;
  }
}