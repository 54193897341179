@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";
label {
  margin: 0;
  padding: 0;
}

.rating {
  border: none;
  display: inline-block;
  &>input {
    display: none;
  }

  &>label:before {
    margin: 5px;
    @include font-size(42);
    display: inline-block;
    content: "\e912";
    font-family: "transvoice-icon" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  &>label {
    color: #ddd;
    float: right;
    padding-right: 15px;
  }
}


.rating>input:checked~label,
/* show gold star when clicked */
.rating input:enabled + label:hover,
/* hover current star */
.rating input:enabled + label:hover~label {
  color: #FFD700;
}

/* hover previous stars in list */

.rating>input:checked:enabled+label:hover,
/* hover current star when changing rating */
.rating>input:checked:enabled~label:hover,
.rating>label:hover~input:checked:enabled~label,
/* lighten current selection */
.rating>input:checked:enabled~label:hover~label {
  color: #FFED85;
}