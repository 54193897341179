@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.calendar-small {
  width: 360px;

  .rdrDay:not(.rdrDayPassive) .rdrInRange~.rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrStartEdge~.rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrEndEdge~.rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrSelected~.rdrDayNumber span {
    color: black
  }

  .rdrDayStartOfMonth .rdrInRange,
  .rdrDayStartOfWeek .rdrInRange {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .rdrDayWeekend{
    .rdrInRange {
      &.not-weekend {
        background-color: $WHITE_COLOR;
      }
    }
  }

  .rdrDayStartOfWeek .rdrEndEdge,
  .rdrDayEndOfWeek .rdrStartEdge {
    border-radius: 50%;
    left: 9px;
  }

  .rdrDayEndOfMonth .rdrInRange,
  .rdrDayEndOfWeek .rdrInRange {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .rdrDay {
    height: 32px;
  }

  .rdrDayDisabled {
    background-color: $WHITE_COLOR;
  }

  .rdrStartEdge,
  .rdrEndEdge {
    background-color: $SECONDARY_COLOR;
    border-radius: 50%;
    left: 9px;
    top: 5px;
    z-index: 1;
    width: 30px;
    height: 30px;
  }
  .rdrDay:not(.rdrDayPassive) {
    .rdrStartEdge {

      &+.rdrDayNumber {
        background: linear-gradient(to right, #ffff 0%, #E1E6FF 100%, #E1E6FF 50%, #E1E6FF 100%);
        &.not-weekend{
          background: $WHITE_COLOR;
        }
        span {
          z-index: 10;
          color: white !important;
        }
      }

      &+span+.rdrDayNumber {
        background: linear-gradient(to right, #ffff 0%, #E1E6FF 100%, #E1E6FF 50%, #E1E6FF 100%);
        &.not-weekend{
          background: $WHITE_COLOR;
        }
        span {
          z-index: 10;
          color: white !important;
        }
      }

      &.rdrEndEdge {
        &+.rdrDayNumber {
          background: transparent;
        }

        &+span+.rdrDayNumber {
          background: transparent;
        }
      }
    }

    .rdrEndEdge {

      &+.rdrDayNumber {
        background: linear-gradient(to right, #E1E6FF 0%, #ffff 100%, #E1E6FF 100%, #ffff 0%);
        &.not-weekend{
          background: $WHITE_COLOR;
        }
        span {
          z-index: 10;
          color: white !important;
        }
      }

      &+span+.rdrDayNumber {
        background: linear-gradient(to right, #E1E6FF 0%, #ffff 100%, #E1E6FF 100%, #ffff 0%);
        &.not-weekend{
          background: $WHITE_COLOR;
        }
        span {
          z-index: 10;
          color: white !important;
        }
      }

      &.rdrStartEdge {
        &+.rdrDayNumber {
          background: transparent;
        }

        &+span+.rdrDayNumber {
          background: transparent;
        }
      }
    }


  }
  .rdrDayToday .rdrDayNumber span {
    color: $PRIMARY_COLOR !important;

    &:after {
      background: none !important;
    }
  }

  .rdrSelected,
  .rdrInRange,
  .rdrStartEdge,
  .rdrEndEdge,
  .rdrDayNumber {
    height: 30px;
  }

  .rdrWeekDay {
    font-size: 14px;
    line-height: 24px;
    color: $NAV_BAR_TEXT_COLOR;
  }

  .rdrMonthAndYearPickers {
    width: 60px;

    .rdrMonthPicker,
    .rdrYearPicker {

      select {
        color: $NAV_BAR_TEXT_COLOR;
        background: $WHITE_COLOR;
        padding: 0;
        font-size: 16px;
        line-height: 24px;
        font-family: "PT Serif";
        width: auto;
        text-align: right;
        // text-align-last: center;

        &:before {
          content: "\e932";
          font-family: "transvoice-icon";
          -webkit-font-smoothing: antialiased;
          text-rendering: optimizeLegibility;
          -moz-osx-font-smoothing: grayscale;
          font-feature-settings: "liga";
          margin: 0 30px 0 0;
          color: #bdbdbd;
          cursor: pointer;

          /* Better Font Rendering =========== */
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
      }
    }
  }

  .rdrCalendarWrapper {
    font-size: 14px;
  }

  .rdrNextPrevButton {
    background: none;

    &::before {
      content: "\e932";
      font-family: "transvoice-icon";
      -webkit-font-smoothing: antialiased;
      text-rendering: optimizeLegibility;
      -moz-osx-font-smoothing: grayscale;
      font-feature-settings: "liga";
      margin: 0 30px 0 0;
      color: #bdbdbd;
      cursor: pointer;
    }

    i {
      display: none;
    }

    &:hover {
      background: none;
    }
  }

  .rdrNextButton {
    background: none;

    &::before {
      content: "\e931";
      font-family: "transvoice-icon";
      -webkit-font-smoothing: antialiased;
      text-rendering: optimizeLegibility;
      -moz-osx-font-smoothing: grayscale;
      font-feature-settings: "liga";
      margin: 0 0 0 20px;
      color: #bdbdbd;
      cursor: pointer;
    }

    i {
      display: none;
    }

    &:hover {
      background: none;
    }
  }

  .rdrMonth {
    width: 360px;
  }

  .rdrMonthAndYearWrapper {
    padding: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid $PURPLE_BLOOD_COLOR;
    height: auto;
  }

  .tv-calendar-time {
    background-color: $WHITE_COLOR;
    border-bottom: 1px solid $PURPLE_BLOOD_COLOR;

    display: flex;
    width: 100%;



    &__item {
      width: 50%;
      padding: 20px;


      &--title {
        color: #A9A9A9;
        font-size: 14px;
        line-height: 20px;

        &::before {
          padding-right: 10px;
          font-size: 16px;
        }
      }
      &-value {
        text-align: center;
        color: $SECONDARY_COLOR;
        @include font-size(16);
        font-weight: bold;
        line-height: 24px;
        margin: 5px 0;
        &.no-icon {
          text-align: left
        }
      }
    }
  }
  .select-weekday{
    background-color: $WHITE_COLOR;
    border-bottom: 1px solid $PURPLE_BLOOD_COLOR;
    justify-content: center;
    padding-bottom: 20px;

    &__title {
      color: $NAV_BAR_TEXT_COLOR;
      font-size: 14px;
      line-height: 24px;
      padding-right: 10px;
    }
  }
  .rc-time-picker-input {
    border: none;
    color: $SECONDARY_COLOR;
    font-size: 14px;
    font-weight: bold;
    line-height: 24px;
    cursor: pointer;
  }

  .rc-time-picker::after {
    color: $SECONDARY_COLOR;
    margin-top: 22px;
  }

  .rc-time-picker-panel-input{
    color: $SECONDARY_COLOR !important;
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    cursor: pointer;
  }

  .rc-time-picker .icon-arrow-expand {
    margin-top: 22px;
  }
}