@import "../../../Share/styles/variables.scss";
@import "../../../Share/styles/mixin.scss";

@mixin resetReactTab {
  margin: 0;
  padding: 0;
  border: none;
}

$TAB_HEADER_HEIGHT: 109px;

.tv-settings {
  &__wrapper {
    width: 800px;
    margin: 0 auto;
    font-family: $PRIMARY_FONT;
  }

  &__title {
    background: transparent;

    font: 500 rem-calc(24)/rem-calc(32) $PRIMARY_FONT;
    letter-spacing: -0.2px;
    color: #353535;
    margin-top: 0px 0px 24px 0px;
  }

  &__tab-text-main {
    font: 300 rem-calc(18)/rem-calc(28) $PRIMARY_FONT;
    color: #313131;
    letter-spacing: -0.2px;
  }

  &__tab-text-sub{
    font: rem-calc(14)/rem-calc(20) $SECONDARY_FONT;
    color: #A9A9A9;
  }

  &__panel-title {
    font: 300 rem-calc(18)/rem-calc(28) $PRIMARY_FONT;
    letter-spacing: -0.2px;
    color: $SECONDARY_COLOR;
    height: $TAB_HEADER_HEIGHT;
    padding-top: 38px;
    border-bottom: 1px solid #e5e5e5;
  }

  &__account-wrapper {
    padding-top: 46px;
    font-family: $SECONDARY_FONT;

    .tx-input-text {
      font-size: 1.25rem;
      width: 100%;
    }

    .tv-form__error {
      display: none;
    }
  }

  &__password-label {
    color: #313131;
    font-family: "PT Serif";
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 5px;
  }

  &__password-field {
    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      opacity: 1; /* Firefox */
      @include font-size(14);
    }
    
    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      @include font-size(14);
    }
    
    &::-ms-input-placeholder { /* Microsoft Edge */
      @include font-size(14);
    }
  }

  &__change-password-btn {
    color: #FA0A5F;
    font-family: "PT Serif";
    font-size: 14px;
    line-height: 24px;
    padding: 8px 0px;
  }

  &__calendar-wrapper {
    padding-top: 46px;
    font-family: $SECONDARY_FONT;

    .tv-toggleSwitch {
      margin-left: auto;
    }
  }

  &__calendar-submit {
    margin: auto 0px 0px auto;

    &.tv-buttons__element {
      padding: 13px 31px;
    }
  }

  &__contact-text {
    font: rem-calc(14)/rem-calc(20) $SECONDARY_FONT;
    color: $DARK_GRAY_COLOR;
    letter-spacing: 0.3px;
    margin-top: 24px;
  }

  &__contact-btn {
    margin-top: 50px;
    &.tv-buttons__element {
      font-weight: 300;
      padding: 15px 24px;
      width: 248px;
    }
  }

  &__term-text {
    color: #A9A9A9;
    font-family: "PT Serif";
    font-size: 14px;
    letter-spacing: 0.3px;
    line-height: 20px;
    height: 100%;
  }

  &__tabs-wrapper {
    background-color: $WHITE_COLOR;
    border-radius: 5px;
    box-shadow: 0 2px 16px 0 rgba(0,0,0,0.12);
    min-height: 672px;
    display: flex;

    // Override react-tab CSS
    .react-tabs {
      &__tab-list {
        flex: 1;
        background-color: #F9F9F9;
        @include resetReactTab();
      }

      &__tab {
        @include resetReactTab();
        display: flex;
        padding: 30px 0px 30px 34px;
        border-bottom: 1px solid #e5e5e5;
        height: $TAB_HEADER_HEIGHT;

        [class*="icon-"] {
          &::before {
            font-size: rem-calc(35);
            display: inline-block;
            margin-right: 17px;
            line-height: 46px;
          }
        }

        &--selected {
          background: inherit;
          border-radius: 0;
          border-right: 3px solid $PRIMARY_COLOR;

          [class*="icon-"]::before,
          .tv-settings__tab-text-main {
            color: $PRIMARY_COLOR;
          }
        }

        &:focus {
          box-shadow: none;
          border-color: none;
          outline: none;

          &:after {
            content: none;
          }
        }
      }

      &__tab-panel {
        flex: 1.5;
        padding: 0px 54px 48px 54px;
        &--selected {
          display: flex;
          flex-direction: column;
        }
      }

      &--vertical {
        .react-tabs {
          &__tab-list {
            width: 100%;
            flex: unset;
            background-color: transparent;
            position: unset;
            border-bottom: solid 1px #e5e5e5;
            padding: 38px 0 38px 0;
            margin-bottom: 20px;
          }

          &__tab {
            color: $DARK_GRAY_COLOR;
            display: inline;
            border-bottom: none;
            padding: 0;
            position: unset;
            font-size: 18px;
            line-height: 28px;

            &:first-child {
              margin-right: 32px;
            }

            &--selected {
              border-right: none;
              text-decoration: underline;
              color: $SECONDARY_COLOR;
            }
          }

          &__tab-panel {
            padding: 0;
            flex: unset;
            flex-direction: unset;

            .tab-panel-content {
              color: $DARK_GRAY_COLOR;
              font: 0.875rem/1.25rem "PT Serif", serif;
              text-align: left;
              font-size: 14px;
              line-height: 20px;
              overflow-y: scroll;
              max-height: 567px;

              &__paragraph {
                line-height: 40px;
              }
            }

            ::-webkit-scrollbar {
              width: 8px;
              &-track {
                background: transparent;
              }
              &-thumb {
                background: $BORDER_COLOR;
                border-radius: 4px;
                &:hover {
                  background: $DARK_GRAY_COLOR;
                }
              }
            }
          }
        }
      }
    }
  }
}