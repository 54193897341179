@import url(https://fonts.googleapis.com/css?family=PT+Serif:400,400i,700,700i&display=swap);
.calendar-small {
  width: 360px; }
  .calendar-small .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
  .calendar-small .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
  .calendar-small .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,
  .calendar-small .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
    color: black; }
  .calendar-small .rdrDayStartOfMonth .rdrInRange,
  .calendar-small .rdrDayStartOfWeek .rdrInRange {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .calendar-small .rdrDayWeekend .rdrInRange.not-weekend {
    background-color: #FFF; }
  .calendar-small .rdrDayStartOfWeek .rdrEndEdge,
  .calendar-small .rdrDayEndOfWeek .rdrStartEdge {
    border-radius: 50%;
    left: 9px; }
  .calendar-small .rdrDayEndOfMonth .rdrInRange,
  .calendar-small .rdrDayEndOfWeek .rdrInRange {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .calendar-small .rdrDay {
    height: 32px; }
  .calendar-small .rdrDayDisabled {
    background-color: #FFF; }
  .calendar-small .rdrStartEdge,
  .calendar-small .rdrEndEdge {
    background-color: #322878;
    border-radius: 50%;
    left: 9px;
    top: 5px;
    z-index: 1;
    width: 30px;
    height: 30px; }
  .calendar-small .rdrDay:not(.rdrDayPassive) .rdrStartEdge + .rdrDayNumber {
    background: linear-gradient(to right, #ffff 0%, #E1E6FF 100%, #E1E6FF 50%, #E1E6FF 100%); }
    .calendar-small .rdrDay:not(.rdrDayPassive) .rdrStartEdge + .rdrDayNumber.not-weekend {
      background: #FFF; }
    .calendar-small .rdrDay:not(.rdrDayPassive) .rdrStartEdge + .rdrDayNumber span {
      z-index: 10;
      color: white !important; }
  .calendar-small .rdrDay:not(.rdrDayPassive) .rdrStartEdge + span + .rdrDayNumber {
    background: linear-gradient(to right, #ffff 0%, #E1E6FF 100%, #E1E6FF 50%, #E1E6FF 100%); }
    .calendar-small .rdrDay:not(.rdrDayPassive) .rdrStartEdge + span + .rdrDayNumber.not-weekend {
      background: #FFF; }
    .calendar-small .rdrDay:not(.rdrDayPassive) .rdrStartEdge + span + .rdrDayNumber span {
      z-index: 10;
      color: white !important; }
  .calendar-small .rdrDay:not(.rdrDayPassive) .rdrStartEdge.rdrEndEdge + .rdrDayNumber {
    background: transparent; }
  .calendar-small .rdrDay:not(.rdrDayPassive) .rdrStartEdge.rdrEndEdge + span + .rdrDayNumber {
    background: transparent; }
  .calendar-small .rdrDay:not(.rdrDayPassive) .rdrEndEdge + .rdrDayNumber {
    background: linear-gradient(to right, #E1E6FF 0%, #ffff 100%, #E1E6FF 100%, #ffff 0%); }
    .calendar-small .rdrDay:not(.rdrDayPassive) .rdrEndEdge + .rdrDayNumber.not-weekend {
      background: #FFF; }
    .calendar-small .rdrDay:not(.rdrDayPassive) .rdrEndEdge + .rdrDayNumber span {
      z-index: 10;
      color: white !important; }
  .calendar-small .rdrDay:not(.rdrDayPassive) .rdrEndEdge + span + .rdrDayNumber {
    background: linear-gradient(to right, #E1E6FF 0%, #ffff 100%, #E1E6FF 100%, #ffff 0%); }
    .calendar-small .rdrDay:not(.rdrDayPassive) .rdrEndEdge + span + .rdrDayNumber.not-weekend {
      background: #FFF; }
    .calendar-small .rdrDay:not(.rdrDayPassive) .rdrEndEdge + span + .rdrDayNumber span {
      z-index: 10;
      color: white !important; }
  .calendar-small .rdrDay:not(.rdrDayPassive) .rdrEndEdge.rdrStartEdge + .rdrDayNumber {
    background: transparent; }
  .calendar-small .rdrDay:not(.rdrDayPassive) .rdrEndEdge.rdrStartEdge + span + .rdrDayNumber {
    background: transparent; }
  .calendar-small .rdrDayToday .rdrDayNumber span {
    color: #FA0A5F !important; }
    .calendar-small .rdrDayToday .rdrDayNumber span:after {
      background: none !important; }
  .calendar-small .rdrSelected,
  .calendar-small .rdrInRange,
  .calendar-small .rdrStartEdge,
  .calendar-small .rdrEndEdge,
  .calendar-small .rdrDayNumber {
    height: 30px; }
  .calendar-small .rdrWeekDay {
    font-size: 14px;
    line-height: 24px;
    color: #313131; }
  .calendar-small .rdrMonthAndYearPickers {
    width: 60px; }
    .calendar-small .rdrMonthAndYearPickers .rdrMonthPicker select,
    .calendar-small .rdrMonthAndYearPickers .rdrYearPicker select {
      color: #313131;
      background: #FFF;
      padding: 0;
      font-size: 16px;
      line-height: 24px;
      font-family: "PT Serif";
      width: auto;
      text-align: right; }
      .calendar-small .rdrMonthAndYearPickers .rdrMonthPicker select:before,
      .calendar-small .rdrMonthAndYearPickers .rdrYearPicker select:before {
        content: "\e932";
        font-family: "transvoice-icon";
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
        -moz-osx-font-smoothing: grayscale;
        font-feature-settings: "liga";
        margin: 0 30px 0 0;
        color: #bdbdbd;
        cursor: pointer;
        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
  .calendar-small .rdrCalendarWrapper {
    font-size: 14px; }
  .calendar-small .rdrNextPrevButton {
    background: none; }
    .calendar-small .rdrNextPrevButton::before {
      content: "\e932";
      font-family: "transvoice-icon";
      -webkit-font-smoothing: antialiased;
      text-rendering: optimizeLegibility;
      -moz-osx-font-smoothing: grayscale;
      font-feature-settings: "liga";
      margin: 0 30px 0 0;
      color: #bdbdbd;
      cursor: pointer; }
    .calendar-small .rdrNextPrevButton i {
      display: none; }
    .calendar-small .rdrNextPrevButton:hover {
      background: none; }
  .calendar-small .rdrNextButton {
    background: none; }
    .calendar-small .rdrNextButton::before {
      content: "\e931";
      font-family: "transvoice-icon";
      -webkit-font-smoothing: antialiased;
      text-rendering: optimizeLegibility;
      -moz-osx-font-smoothing: grayscale;
      font-feature-settings: "liga";
      margin: 0 0 0 20px;
      color: #bdbdbd;
      cursor: pointer; }
    .calendar-small .rdrNextButton i {
      display: none; }
    .calendar-small .rdrNextButton:hover {
      background: none; }
  .calendar-small .rdrMonth {
    width: 360px; }
  .calendar-small .rdrMonthAndYearWrapper {
    padding: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #E1E6FF;
    height: auto; }
  .calendar-small .tv-calendar-time {
    background-color: #FFF;
    border-bottom: 1px solid #E1E6FF;
    display: flex;
    width: 100%; }
    .calendar-small .tv-calendar-time__item {
      width: 50%;
      padding: 20px; }
      .calendar-small .tv-calendar-time__item--title {
        color: #A9A9A9;
        font-size: 14px;
        line-height: 20px; }
        .calendar-small .tv-calendar-time__item--title::before {
          padding-right: 10px;
          font-size: 16px; }
      .calendar-small .tv-calendar-time__item-value {
        text-align: center;
        color: #322878;
        font-size: 16px;
        font-size: 1rem;
        font-weight: bold;
        line-height: 24px;
        margin: 5px 0; }
        .calendar-small .tv-calendar-time__item-value.no-icon {
          text-align: left; }
  .calendar-small .select-weekday {
    background-color: #FFF;
    border-bottom: 1px solid #E1E6FF;
    justify-content: center;
    padding-bottom: 20px; }
    .calendar-small .select-weekday__title {
      color: #313131;
      font-size: 14px;
      line-height: 24px;
      padding-right: 10px; }
  .calendar-small .rc-time-picker-input {
    border: none;
    color: #322878;
    font-size: 14px;
    font-weight: bold;
    line-height: 24px;
    cursor: pointer; }
  .calendar-small .rc-time-picker::after {
    color: #322878;
    margin-top: 22px; }
  .calendar-small .rc-time-picker-panel-input {
    color: #322878 !important;
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    cursor: pointer; }
  .calendar-small .rc-time-picker .icon-arrow-expand {
    margin-top: 22px; }

.tv-toggleSwitch {
  position: relative;
  display: inline-block; }
  .tv-toggleSwitch.tv-toggleSwitch__rounded .Slider {
    border-radius: 15rem;
    background-color: #C5C5C5; }
    .tv-toggleSwitch.tv-toggleSwitch__rounded .Slider:before {
      border-radius: 50%; }
  .tv-toggleSwitch .tv-toggleSwitch__wrapper {
    position: relative;
    width: 60px;
    height: 30px; }
    .tv-toggleSwitch .tv-toggleSwitch__wrapper .Slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transition: .4s ease; }
      .tv-toggleSwitch .tv-toggleSwitch__wrapper .Slider:before {
        width: 24px;
        height: 24px;
        position: absolute;
        background-color: #FFF;
        content: '';
        margin: 0;
        padding: 0;
        top: 50%;
        left: 0.2rem;
        transform: translateY(-50%);
        transition: .4s;
        cursor: pointer;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); }
      .tv-toggleSwitch .tv-toggleSwitch__wrapper .Slider.isChecked {
        background: #322878; }
        .tv-toggleSwitch .tv-toggleSwitch__wrapper .Slider.isChecked:before {
          left: calc(100% - 1.7rem);
          background: #FFF; }

.tv-block-wrapper {
  display: flex;
  flex-direction: column;
  width: 360px;
  box-shadow: 0 0 13px 0 rgba(223, 223, 223, 0.5); }
  .tv-block-wrapper__title {
    color: #313131;
    font-family: "PT Serif";
    font-size: 14px;
    line-height: 24px;
    padding: 14px 24px;
    background-color: #F9F9F9; }

.tv-date-range-picker__dialog {
  position: absolute;
  background: #FFF;
  z-index: 5;
  box-shadow: 0 10px 18px -12px rgba(58, 68, 131, 0.2);
  border-radius: 0 4px 4px 4px; }

label {
  margin: 0;
  padding: 0; }

.rating {
  border: none;
  display: inline-block; }
  .rating > input {
    display: none; }
  .rating > label:before {
    margin: 5px;
    font-size: 42px;
    font-size: 2.625rem;
    display: inline-block;
    content: "\e912";
    font-family: "transvoice-icon" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
  .rating > label {
    color: #ddd;
    float: right;
    padding-right: 15px; }

.rating > input:checked ~ label,
.rating input:enabled + label:hover,
.rating input:enabled + label:hover ~ label {
  color: #FFD700; }

/* hover previous stars in list */
.rating > input:checked:enabled + label:hover,
.rating > input:checked:enabled ~ label:hover,
.rating > label:hover ~ input:checked:enabled ~ label,
.rating > input:checked:enabled ~ label:hover ~ label {
  color: #FFED85; }

.form_textarea {
  position: relative;
  padding-top: 20px; }
  .form_textarea__form-control {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    resize: none;
    color: #A9A9A9;
    background-clip: padding-box;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    font: 0.875rem/1.25rem "PT Serif", serif;
    border: 1px solid #DFE7EA;
    border-radius: 5px;
    background-color: #FFF;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.04); }
  .form_textarea__description {
    position: absolute;
    bottom: 24px;
    right: 24px;
    color: #A9A9A9;
    font: 0.875rem/1.25rem "PT Serif", serif;
    letter-spacing: 0.3px; }

.tv-not-found-page {
  width: 100%;
  height: calc(100vh - 200px);
  display: flex;
  flex-direction: column; }
  .tv-not-found-page__image {
    height: 342px;
    width: 324px;
    background-image: url("/images/not-found.svg");
    background-size: cover;
    background-position-y: center;
    margin: auto; }
  .tv-not-found-page__title {
    font: 1.5rem/2rem "Campton", sans-serif;
    font-weight: 500;
    letter-spacing: -0.2px;
    color: #FA0A5F; }

.thanks-wrapper {
  width: 700px;
  height: 600px;
  border-radius: 5px;
  background-color: #FFF;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.12);
  margin: 0 auto;
  padding: 40px 130px 70px;
  text-align: center;
  color: #313131;
  display: flex;
  flex-direction: column;
  font: 1rem/1.5rem "PT Serif", serif; }
  .thanks-wrapper__header {
    height: 200px;
    width: 240px;
    background-image: url("/images/thank-you.svg");
    background-size: cover;
    background-position-y: center;
    margin: 0 auto; }
  .thanks-wrapper__content {
    padding-top: 20px; }
    .thanks-wrapper__content h1 {
      color: #313131;
      font: 2rem/2.5rem "Campton", sans-serif;
      font-weight: 300;
      letter-spacing: -0.5px; }
  .thanks-wrapper__footer {
    margin-top: auto; }
    .thanks-wrapper__footer button {
      padding: 15px 100px; }

.tv-radialgauge-container {
  position: relative; }

.tv-radialgauge-inner_detail {
  position: absolute;
  display: flex;
  flex-direction: column;
  text-align: center;
  bottom: 10px;
  left: 0px;
  width: 100%; }

.tv-right-status .icon-search {
  color: #000; }

.tv-right-status a:hover {
  text-decoration: none; }

#k-panelbar-item-default-\.0 > span > span.k-icon.k-i-paste-plain-text {
  margin-right: 10px; }

#k-panelbar-item-default-\.0 > span {
  color: #313131;
  font-weight: 100 !important;
  font: 0.875rem/1.5rem "Campton", sans-serif; }

.tv-settings__wrapper {
  width: 800px;
  margin: 0 auto;
  font-family: "Campton", sans-serif; }

.tv-settings__title {
  background: transparent;
  font: 500 1.5rem/2rem "Campton", sans-serif;
  letter-spacing: -0.2px;
  color: #353535;
  margin-top: 0px 0px 24px 0px; }

.tv-settings__tab-text-main {
  font: 300 1.125rem/1.75rem "Campton", sans-serif;
  color: #313131;
  letter-spacing: -0.2px; }

.tv-settings__tab-text-sub {
  font: 0.875rem/1.25rem "PT Serif", serif;
  color: #A9A9A9; }

.tv-settings__panel-title {
  font: 300 1.125rem/1.75rem "Campton", sans-serif;
  letter-spacing: -0.2px;
  color: #322878;
  height: 109px;
  padding-top: 38px;
  border-bottom: 1px solid #e5e5e5; }

.tv-settings__account-wrapper {
  padding-top: 46px;
  font-family: "PT Serif", serif; }
  .tv-settings__account-wrapper .tx-input-text {
    font-size: 1.25rem;
    width: 100%; }
  .tv-settings__account-wrapper .tv-form__error {
    display: none; }

.tv-settings__password-label {
  color: #313131;
  font-family: "PT Serif";
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 5px; }

.tv-settings__password-field::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  opacity: 1;
  /* Firefox */
  font-size: 14px;
  font-size: 0.875rem; }

.tv-settings__password-field:-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  opacity: 1;
  /* Firefox */
  font-size: 14px;
  font-size: 0.875rem; }

.tv-settings__password-field::-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  opacity: 1;
  /* Firefox */
  font-size: 14px;
  font-size: 0.875rem; }

.tv-settings__password-field::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  opacity: 1;
  /* Firefox */
  font-size: 14px;
  font-size: 0.875rem; }

.tv-settings__password-field:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-size: 14px;
  font-size: 0.875rem; }

.tv-settings__password-field::-ms-input-placeholder {
  /* Microsoft Edge */
  font-size: 14px;
  font-size: 0.875rem; }

.tv-settings__change-password-btn {
  color: #FA0A5F;
  font-family: "PT Serif";
  font-size: 14px;
  line-height: 24px;
  padding: 8px 0px; }

.tv-settings__calendar-wrapper {
  padding-top: 46px;
  font-family: "PT Serif", serif; }
  .tv-settings__calendar-wrapper .tv-toggleSwitch {
    margin-left: auto; }

.tv-settings__calendar-submit {
  margin: auto 0px 0px auto; }
  .tv-settings__calendar-submit.tv-buttons__element {
    padding: 13px 31px; }

.tv-settings__contact-text {
  font: 0.875rem/1.25rem "PT Serif", serif;
  color: #A9A9A9;
  letter-spacing: 0.3px;
  margin-top: 24px; }

.tv-settings__contact-btn {
  margin-top: 50px; }
  .tv-settings__contact-btn.tv-buttons__element {
    font-weight: 300;
    padding: 15px 24px;
    width: 248px; }

.tv-settings__term-text {
  color: #A9A9A9;
  font-family: "PT Serif";
  font-size: 14px;
  letter-spacing: 0.3px;
  line-height: 20px;
  height: 100%; }

.tv-settings__tabs-wrapper {
  background-color: #FFF;
  border-radius: 5px;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.12);
  min-height: 672px;
  display: flex; }
  .tv-settings__tabs-wrapper .react-tabs__tab-list {
    flex: 1 1;
    background-color: #F9F9F9;
    margin: 0;
    padding: 0;
    border: none; }
  .tv-settings__tabs-wrapper .react-tabs__tab {
    margin: 0;
    padding: 0;
    border: none;
    display: flex;
    padding: 30px 0px 30px 34px;
    border-bottom: 1px solid #e5e5e5;
    height: 109px; }
    .tv-settings__tabs-wrapper .react-tabs__tab [class*="icon-"]::before {
      font-size: 2.1875rem;
      display: inline-block;
      margin-right: 17px;
      line-height: 46px; }
    .tv-settings__tabs-wrapper .react-tabs__tab--selected {
      background: inherit;
      border-radius: 0;
      border-right: 3px solid #FA0A5F; }
      .tv-settings__tabs-wrapper .react-tabs__tab--selected [class*="icon-"]::before,
      .tv-settings__tabs-wrapper .react-tabs__tab--selected .tv-settings__tab-text-main {
        color: #FA0A5F; }
    .tv-settings__tabs-wrapper .react-tabs__tab:focus {
      box-shadow: none;
      border-color: none;
      outline: none; }
      .tv-settings__tabs-wrapper .react-tabs__tab:focus:after {
        content: none; }
  .tv-settings__tabs-wrapper .react-tabs__tab-panel {
    flex: 1.5 1;
    padding: 0px 54px 48px 54px; }
    .tv-settings__tabs-wrapper .react-tabs__tab-panel--selected {
      display: flex;
      flex-direction: column; }
  .tv-settings__tabs-wrapper .react-tabs--vertical .react-tabs__tab-list {
    width: 100%;
    flex: unset;
    background-color: transparent;
    position: unset;
    border-bottom: solid 1px #e5e5e5;
    padding: 38px 0 38px 0;
    margin-bottom: 20px; }
  .tv-settings__tabs-wrapper .react-tabs--vertical .react-tabs__tab {
    color: #A9A9A9;
    display: inline;
    border-bottom: none;
    padding: 0;
    position: unset;
    font-size: 18px;
    line-height: 28px; }
    .tv-settings__tabs-wrapper .react-tabs--vertical .react-tabs__tab:first-child {
      margin-right: 32px; }
    .tv-settings__tabs-wrapper .react-tabs--vertical .react-tabs__tab--selected {
      border-right: none;
      text-decoration: underline;
      color: #322878; }
  .tv-settings__tabs-wrapper .react-tabs--vertical .react-tabs__tab-panel {
    padding: 0;
    flex: unset;
    flex-direction: unset; }
    .tv-settings__tabs-wrapper .react-tabs--vertical .react-tabs__tab-panel .tab-panel-content {
      color: #A9A9A9;
      font: 0.875rem/1.25rem "PT Serif", serif;
      text-align: left;
      font-size: 14px;
      line-height: 20px;
      overflow-y: scroll;
      max-height: 567px; }
      .tv-settings__tabs-wrapper .react-tabs--vertical .react-tabs__tab-panel .tab-panel-content__paragraph {
        line-height: 40px; }
    .tv-settings__tabs-wrapper .react-tabs--vertical .react-tabs__tab-panel ::-webkit-scrollbar {
      width: 8px; }
      .tv-settings__tabs-wrapper .react-tabs--vertical .react-tabs__tab-panel ::-webkit-scrollbar-track {
        background: transparent; }
      .tv-settings__tabs-wrapper .react-tabs--vertical .react-tabs__tab-panel ::-webkit-scrollbar-thumb {
        background: #f1f1f1;
        border-radius: 4px; }
        .tv-settings__tabs-wrapper .react-tabs--vertical .react-tabs__tab-panel ::-webkit-scrollbar-thumb:hover {
          background: #A9A9A9; }

/* Campton @font-face kit */
@font-face {
  font-family: "Campton";
  src: url("/fonts/Campton/Rene Bieder - Campton Medium.otf");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: "Campton";
  src: url("/fonts/Campton/Rene Bieder - Campton Book.otf");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: "transvoice-icon";
  src: url("/fonts/transvoice-icon/transvoice-icon.eot?ysq9j9");
  src: url("/fonts/transvoice-icon/transvoice-icon.eot?ysq9j9#iefix") format("embedded-opentype"), url("/fonts/transvoice-icon/transvoice-icon.ttf?ysq9j9") format("truetype"), url("/fonts/transvoice-icon/transvoice-icon.woff?ysq9j9") format("woff"), url("/fonts/transvoice-icon/transvoice-icon.svg?ysq9j9#transvoice-icon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"]:before,
[class*=" icon-"]:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "transvoice-icon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-filter:before {
  content: "\e900";
  color: #c5c5c5; }

.icon-your-compensation:before {
  content: "\e901";
  color: #c5c5c5; }

.icon-warning:before {
  content: "\e902";
  color: #c0c2c3; }

.icon-waiting-to-be-submitted:before {
  content: "\e903";
  color: #fff; }

.icon-trophy:before {
  content: "\e904";
  color: #bdbdbd; }

.icon-timer:before {
  content: "\e905";
  color: #c5c5c5; }

.icon-the-envelope:before {
  content: "\e906";
  color: #c5c5c5; }

.icon-task:before {
  content: "\e907";
  color: #c5c5c5; }

.icon-submit-time:before {
  content: "\e908";
  color: #c5c5c5; }

.icon-small:before {
  content: "\e909";
  color: #bdbdbd; }

.icon-share:before {
  content: "\e90a";
  color: #c5c5c5; }

.icon-search:before {
  content: "\e90b"; }

.icon-schedule:before {
  content: "\e90c";
  color: #bdbdbd; }

.icon-report-quality:before {
  content: "\e90d";
  color: #c5c5c5; }

.icon-question:before {
  content: "\e90e";
  color: #c0c2c3; }

.icon-profile:before {
  content: "\e90f";
  color: #c5c5c5; }

.icon-pointer:before {
  content: "\e910";
  color: #c5c5c5; }

.icon-photo:before {
  content: "\e911";
  color: #9e9e9e; }

.icon-news:before {
  content: "\e912";
  color: #c5c5c5; }

.icon-lock:before {
  content: "\e913";
  color: #c0c2c3; }

.icon-interprete-now:before {
  content: "\e914";
  color: #c5c5c5; }

.icon-interpret-now-2:before {
  content: "\e915";
  color: #fff; }

.icon-user:before {
  content: "\e916";
  color: #bdbdbd; }

.icon-hourglass:before {
  content: "\e917";
  color: #c9c9c9; }

.icon-home:before {
  content: "\e918";
  color: #c5c5c5; }

.icon-handset:before {
  content: "\e919";
  color: #c5c5c5; }

.icon-handset-2:before {
  content: "\e91a";
  color: #c5c5c5; }

.icon-flag:before {
  content: "\e91b";
  color: #bdbdbd; }

.icon-fire:before {
  content: "\e91c";
  color: #fff; }

.icon-drawing-pin:before {
  content: "\e91d";
  color: #bdbdbd; }

.icon-download-to-cloud:before {
  content: "\e91e";
  color: #c0c2c3; }

.icon-dollar:before {
  content: "\e91f";
  color: #fff; }

.icon-dollar-2:before {
  content: "\e920";
  color: #d1d1d1; }

.icon-create-new:before {
  content: "\e921";
  color: #fff; }

.icon-contact:before {
  content: "\e922";
  color: #c5c5c5; }

.icon-contact-2:before {
  content: "\e923";
  color: #c5c5c5; }

.icon-comment:before {
  content: "\e924";
  color: #c0c2c3; }

.icon-comment-2:before {
  content: "\e925";
  color: #fff; }

.icon-close:before {
  content: "\e926";
  color: #313131; }

.icon-certificate:before {
  content: "\e927";
  color: #bdbdbd; }

.icon-camera:before {
  content: "\e928";
  color: #fa0a5f; }

.icon-calendar:before {
  content: "\e929";
  color: #bdbdbd; }

.icon-browse-assignments:before {
  content: "\e92a";
  color: #c5c5c5; }

.icon-book:before {
  content: "\e92b";
  color: #c5c5c5; }

.icon-book-2:before {
  content: "\e92c";
  color: #c0c2c3; }

.icon-bell:before {
  content: "\e92d";
  color: #c5c5c5; }

.icon-available-request:before {
  content: "\e92e";
  color: #c5c5c5; }

.icon-available-request-2:before {
  content: "\e92f";
  color: #fff; }

.icon-attach:before {
  content: "\e930";
  color: #9e9e9e; }

.icon-arrow-more:before {
  content: "\e931";
  color: #c5c5c5; }

.icon-arrow-more-2:before {
  content: "\e932";
  color: #c5c5c5; }

.icon-arrow-expand:before {
  content: "\e933"; }

.icon-arrow-back:before {
  content: "\e934"; }

.icon-settings:before {
  content: "\e935";
  color: #c5c5c5; }

.icon-envelope:before {
  content: "\e936";
  color: #c5c5c5; }

.icon-log-out:before {
  content: "\e937";
  color: #fa0a5f; }

.icon-more:before {
  content: "\e938";
  color: #c5c5c5; }

.icon-check:before {
  content: "\e939";
  color: #c5c5c5; }

.icon-doc:before {
  content: "\e93a";
  color: #c5c5c5; }

.icon-pdf:before {
  content: "\e93b";
  color: #c5c5c5; }

.icon-download-file:before {
  content: "\e93c";
  color: #c5c5c5; }

.icon-upload-to-cloud:before {
  content: "\e93d";
  color: #c5c5c5; }

.icon-single:before {
  content: "\e93e";
  color: #c5c5c5; }

.icon-multi:before {
  content: "\e93f";
  color: #c5c5c5; }

.icon-image-file:before {
  content: "\e940";
  color: #c5c5c5; }

.icon-mobile-phone:before {
  content: "\e941";
  color: #c5c5c5; }

.icon-customer-order-number:before {
  content: "\e942";
  color: #c5c5c5; }

html,
body {
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
  font-family: "PT Serif"; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

* {
  outline: none; }

a {
  text-decoration: none; }
  a:active, a:focus {
    text-decoration: none; }
  a:hover {
    text-decoration: underline; }

.bn_topbar a:hover {
  text-decoration: none; }

input {
  /*-webkit-appearance:none;*/
  box-sizing: border-box; }

*,
*:before,
*:after {
  box-sizing: inherit; }

body {
  background-color: #FAFAFA;
  margin: 0; }

html {
  box-sizing: border-box;
  font-size: 16px; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #e5e5e5;
  margin: 1em 0;
  padding: 0; }

.tv-container {
  margin: 0 auto; }

.tv-headline-0 {
  font-family: "Campton", sans-serif;
  font-size: 48px;
  font-weight: 500;
  letter-spacing: -0.5px;
  line-height: 54px; }

.tv-headline-1 {
  font-family: "Campton", sans-serif;
  font-size: 32px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 40px; }

.tv-headline-2 {
  font-family: "Campton", sans-serif;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -0.2px;
  line-height: 32px; }

.tv-title-text {
  font-family: "Campton", sans-serif;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: -0.2px;
  line-height: 28px; }

.tv-body-text {
  font-family: "PT Serif", serif;
  font-size: 16px;
  line-height: 24px; }

.tv-small-text {
  font-family: "PT Serif", serif;
  font-size: 14px;
  line-height: 24px; }

.tv-display-flex {
  display: flex; }
  .tv-display-flex-coloumn {
    flex-direction: column; }

.tv-position-rel {
  position: relative; }

.tv-w-50 {
  width: 50%; }

.tv-w-100 {
  width: 100%; }

.tv-primary-color {
  color: #FA0A5F; }

.tv-secondary-color {
  color: #322878; }

.tv-text-color {
  color: #323232; }

.tv-text-inactive-color {
  color: #C5C5C5; }

.tv-space-40 {
  padding-right: 40px; }

[role="button"],
[class^="tv-buttons__type"],
[class*=" tv-buttons__type"] {
  cursor: pointer;
  outline: none;
  font-size: 16px; }

.tv-border-radius-shadow, .tv-filter-list__menu, .tv-workassignment__list-item, .tv-youravailability__list-item, .tv-youravailability__list-item-drawer {
  background: #FFF;
  border-radius: 5px;
  box-shadow: 0 10px 18px -12px rgba(58, 68, 131, 0.2); }

.tv-bottom-line {
  border-top: 1px solid #f1f1f1; }

.tv-is-clipped {
  overflow: hidden; }

.tv-is-clipped2 {
  overflow: hidden; }

.tv-loading__wrapper:before {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  content: "";
  background: rgba(0, 0, 0, 0.3);
  z-index: 10000; }

.tv-loading__content {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10010; }

.tv-loading__spinner {
  width: 75px;
  height: 75px;
  display: inline-block;
  border-width: 6px;
  border-color: #FFF;
  border-top-color: #FA0A5F;
  border-bottom-color: #FA0A5F;
  -webkit-animation: spin 1.5s infinite linear;
          animation: spin 1.5s infinite linear;
  border-radius: 100%;
  border-style: solid; }

.m-auto {
  margin: auto; }

.w-100 {
  width: 100%; }

.tv-modal__wrapper {
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0; }

.tv-modal__overlay {
  content: "";
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 12; }

.tv-modal__holder {
  max-height: calc(100vh - 70px);
  z-index: 20;
  padding: 0 10px;
  -webkit-animation: zoomIn .5s;
          animation: zoomIn .5s;
  max-width: calc(100vh - 100px);
  overflow-y: auto;
  position: absolute; }

.tv-modal__btn-close {
  position: absolute;
  right: 20px;
  top: 10px; }

.tv-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -12px;
  margin-left: -12px; }

.tv-row > [class^=tv-col-] {
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px; }

.tv-col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.tv-col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.tv-col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.tv-col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.tv-col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.tv-col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.tv-col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.tv-col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.tv-col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.tv-col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.tv-col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.tv-col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

@-webkit-keyframes spin {
  100% {
    transform: rotate(360deg); } }

@keyframes spin {
  100% {
    transform: rotate(360deg); } }

@-webkit-keyframes zoomIn {
  0% {
    transform: scale(0.9); }
  to {
    transform: scale(1); } }

@keyframes zoomIn {
  0% {
    transform: scale(0.9); }
  to {
    transform: scale(1); } }

.tv-buttons__normal, .tk-calendar__button {
  border: 0;
  background: transparent; }

.tv-buttons__element {
  padding: 11px 10px 9px;
  font: 300 1rem "Campton", sans-serif;
  border-radius: 5px;
  text-align: center;
  border: 0;
  background: transparent;
  cursor: pointer;
  outline: none; }
  .tv-buttons__element:hover {
    text-decoration: none; }
  .tv-buttons__element--primary {
    color: #FFF;
    background: #322878;
    border: 1px solid #322878; }
    .tv-buttons__element--primary.tv-buttons__element--disable {
      border: 1px solid #C5C5C5; }
  .tv-buttons__element--secondary {
    background: #FFF;
    color: #322878; }
  .tv-buttons__element--line-default {
    border: 1px solid #FFF;
    color: #FFF; }
  .tv-buttons__element--line-primary {
    color: #322878;
    border: 1px solid #322878; }
  .tv-buttons__element--line-secondary {
    color: #313131;
    border: 1px solid #323232; }
  .tv-buttons__element--disable {
    cursor: context-menu;
    color: #FFF;
    background: #C5C5C5; }
  .tv-buttons__element--text {
    color: #FA0A5F; }

.tv-buttons__type {
  background: transparent;
  border: 0;
  padding: 0; }

.tv-filter-list__element {
  position: relative;
  font-size: 0.875rem; }

.tv-filter-list__user-name {
  padding-left: 20px;
  color: #322878;
  cursor: pointer;
  position: absolute;
  top: 10px;
  width: max-content;
  width: -moz-max-content;
  width: -webkit-max-content;
  width: -o-max-content;
  /* width: -ms-max-content;*/ }
  .tv-filter-list__user-name.ie-fix {
    white-space: nowrap;
    width: auto; }

.tv-filter-list__button {
  font-family: "PT Serif", serif;
  color: #313131;
  text-transform: uppercase; }
  .tv-filter-list__button:before {
    content: "\e900";
    font-family: "transvoice-icon";
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: "liga";
    color: #c5c5c5;
    margin-right: 9px; }
  .tv-filter-list__button:after {
    margin-left: 8px;
    content: "\e933";
    font-family: "transvoice-icon";
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: "liga";
    color: #313131;
    font-size: 0.8rem; }
  .tv-filter-list__button:hover {
    text-decoration: none; }

.tv-filter-list__menu {
  font-family: "PT Serif", serif;
  position: absolute;
  margin: 12px 0 0 0;
  padding: 0;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content; }
  .tv-filter-list__menu-item {
    border-bottom: 2px solid #f3f5f7;
    list-style: none; }
    .tv-filter-list__menu-item:last-child {
      border-bottom: 0; }
    .tv-filter-list__menu-item button {
      font-family: "PT Serif", serif;
      color: #313131;
      padding: 18px 24px 15px 14px;
      width: 100%;
      text-align: left; }

.tv-avatar-list__menu {
  background-color: #FFF;
  box-shadow: 3px -5px 16px 0 rgba(159, 159, 159, 0.52);
  position: absolute;
  top: 38px;
  left: -8px;
  margin-top: 12px;
  min-width: 250px;
  z-index: 100;
  font-family: "PT Serif", serif; }
  .tv-avatar-list__menu:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    margin-top: -16px;
    margin-left: 19px;
    border-width: 8px;
    border-style: solid;
    border-color: transparent transparent #FFF transparent; }
  .tv-avatar-list__menu-availability-btn {
    font: 300 1rem/1.4375rem "Campton", sans-serif;
    padding: 8px 20px;
    margin-top: 16px; }
  .tv-avatar-list__menu-item {
    display: block;
    color: #313131;
    display: block;
    padding: 13px 20px;
    font-size: 0.875rem; }
    .tv-avatar-list__menu-item.active {
      background-color: #eeeeee; }
    .tv-avatar-list__menu-item:hover {
      text-decoration: none;
      background-color: #eeeeee; }
    .tv-avatar-list__menu-item-icon {
      margin-right: 10px;
      font-size: 0.9375rem; }
    .tv-avatar-list__menu-item--name {
      padding-top: 20px;
      padding-bottom: 0px;
      font-size: 1rem; }
      .tv-avatar-list__menu-item--name.tv-avatar-list__menu-item:hover {
        background-color: #FFF; }
    .tv-avatar-list__menu-item--customer-name {
      font: 1rem/1.5rem "PT Serif", serif;
      padding-top: 20px;
      padding-bottom: 0px; }
      .tv-avatar-list__menu-item--customer-name.tv-avatar-list__menu-item:hover {
        background-color: #FFF; }
    .tv-avatar-list__menu-item--customer-number {
      font: 0.875rem/1.25rem "PT Serif", serif;
      color: #A9A9A9;
      letter-spacing: 0.3px;
      border-bottom: 1px solid #e5e5e5; }
      .tv-avatar-list__menu-item--customer-number.tv-avatar-list__menu-item:hover {
        background-color: #FFF; }
    .tv-avatar-list__menu-item--id {
      color: #FA0A5F;
      padding-top: 0px;
      padding-bottom: 17px;
      border-bottom: 1px solid #e5e5e5;
      font-size: 0.875rem; }
      .tv-avatar-list__menu-item--id.tv-avatar-list__menu-item:hover {
        background-color: #FFF; }
    .tv-avatar-list__menu-item--profile {
      padding-top: 20px; }
    .tv-avatar-list__menu-item--logout {
      padding-bottom: 32px; }

.tx-input-text {
  background-color: transparent;
  outline: none;
  outline-style: none;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #ededed;
  padding: 10px 0px;
  font: 0.875rem/1.25rem "PT Serif", serif;
  color: #313131; }
  .tx-input-text:focus {
    border-bottom: 1px solid #6478e6; }
  .tx-input-text.tv-form__control--error {
    border-bottom: 1px solid #FA0A5F; }
  .tx-input-text--inactive {
    color: #a9a9a9;
    -webkit-text-fill-color: #a9a9a9;
    -webkit-opacity: 1; }
  .tx-input-text--border {
    border: 1px solid #c5c5c5;
    border-radius: 4px;
    padding: 13px 20px; }
    .tx-input-text--border:focus {
      border-color: #322878; }

.tv-toggle-button {
  display: inline-block;
  min-width: 145px;
  height: 49px;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: #6478e6;
  font-family: "PT Serif", serif;
  font-size: 14px;
  line-height: 49px;
  text-align: center;
  color: white;
  cursor: pointer;
  outline: none; }
  .tv-toggle-button--phone:before {
    content: "\e91a";
    margin-right: 15px;
    font-family: "transvoice-icon";
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: "liga"; }
  .tv-toggle-button--onsite:before {
    content: "\e910";
    margin-right: 15px;
    font-family: "transvoice-icon";
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: "liga"; }
  .tv-toggle-button--unselected {
    border-radius: 5px;
    border: 1px solid #6478e6;
    background-color: white;
    color: #313131; }
    .tv-toggle-button--unselected.tv-toggle-button--phone:before {
      color: #c5c5c5; }
    .tv-toggle-button--unselected.tv-toggle-button--onsite:before {
      color: #c5c5c5; }

.tv-date-picker {
  height: 52px;
  width: 188px;
  padding: 16px 20px;
  border: 1px solid #c5c5c5;
  border-radius: 4px;
  background-color: #ffffff;
  position: relative;
  font-family: "PT Serif";
  font-size: 14px;
  line-height: 20px;
  color: #313131;
  cursor: pointer; }
  .tv-date-picker--inactive {
    color: #a9a9a9; }
  .tv-date-picker::after {
    content: "\e933";
    font-family: "transvoice-icon";
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: "liga";
    font-size: 10px;
    color: #c5c5c5;
    position: absolute;
    top: 0px;
    right: 0px;
    margin-right: 20px;
    margin-top: 14px; }

.tv-attachment {
  width: 130px;
  border: none;
  padding: 0;
  outline: none;
  cursor: pointer; }
  .tv-attachment__header {
    height: 80px;
    border-radius: 5px 5px 0 0;
    display: flex;
    align-items: center;
    justify-content: center; }
    .tv-attachment__header--doc, .tv-attachment__header--docx {
      background-color: #6478e6; }
      .tv-attachment__header--doc::after, .tv-attachment__header--docx::after {
        content: "\e93a";
        font-family: "transvoice-icon";
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
        -moz-osx-font-smoothing: grayscale;
        font-feature-settings: "liga";
        font-size: 38px;
        color: #FFF; }
    .tv-attachment__header--pdf {
      background-color: #ff9ba0; }
      .tv-attachment__header--pdf::after {
        content: "\e93b";
        font-family: "transvoice-icon";
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
        -moz-osx-font-smoothing: grayscale;
        font-feature-settings: "liga";
        font-size: 40px;
        color: #FFF; }
    .tv-attachment__header--image {
      background-color: #454c98; }
      .tv-attachment__header--image::after {
        content: "\e940";
        font-family: "transvoice-icon";
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
        -moz-osx-font-smoothing: grayscale;
        font-feature-settings: "liga";
        font-size: 40px;
        color: #FFF; }
    .tv-attachment__header--other {
      background-color: #454c98; }
      .tv-attachment__header--other::after {
        content: "\e930";
        font-family: "transvoice-icon";
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
        -moz-osx-font-smoothing: grayscale;
        font-feature-settings: "liga";
        font-size: 40px;
        color: #FFF; }
  .tv-attachment__details {
    height: 40px;
    border: 1px solid #eaeaea;
    border-top: none;
    border-radius: 0 0 5px 5px;
    background-color: #f8f8f8;
    color: #322878;
    font: 0.625rem/2.5rem "PT Serif", serif;
    text-align: center; }
    .tv-attachment__details::before {
      content: "\e93c";
      font-family: "transvoice-icon";
      -webkit-font-smoothing: antialiased;
      text-rendering: optimizeLegibility;
      -moz-osx-font-smoothing: grayscale;
      font-feature-settings: "liga";
      font-size: 10px;
      color: #322978;
      margin-right: 8px; }

.tv-file {
  border: none;
  padding: 0;
  outline: none;
  cursor: pointer;
  background-color: transparent; }
  .tv-file__details {
    background-color: transparent;
    margin-top: 16px;
    align-items: center;
    justify-content: center;
    color: #322878;
    font: 0.875rem/1.5rem "PT Serif", serif; }
    .tv-file__details::before {
      content: "\e91e";
      font-family: "transvoice-icon";
      -webkit-font-smoothing: antialiased;
      text-rendering: optimizeLegibility;
      -moz-osx-font-smoothing: grayscale;
      font-feature-settings: "liga";
      font-size: 25px;
      color: #FA0A5F;
      margin-right: 8px; }
    .tv-file__details-text {
      text-decoration: underline; }

.tv-single-assign-form__hr {
  border-top: 1px solid #C5C5C5; }

.tv-single-assign-form__title {
  color: #353535;
  font: 1.5rem/2rem "Campton", sans-serif;
  font-weight: 500;
  letter-spacing: -0.2px;
  text-align: center; }

.tv-single-assign-form__container {
  width: 700px;
  background: #FFF;
  border-radius: 5px;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.12);
  margin: 0 auto 32px auto; }
  .tv-single-assign-form__container--order {
    margin: 0 auto 24px auto; }
    .tv-single-assign-form__container--order .tv-single-assign-form__control {
      color: #313131; }
    .tv-single-assign-form__container--order .tv-single-assign-form__panel {
      padding-bottom: 30px; }
  .tv-single-assign-form__container .tv-row {
    margin-right: -12px;
    margin-left: -12px; }
    .tv-single-assign-form__container .tv-row > [class^="tv-col-"] {
      padding-right: 12px;
      padding-left: 12px; }
  .tv-single-assign-form__container .tv-form__error {
    margin: 4px 0 3px;
    min-height: 1px;
    display: block; }
  .tv-single-assign-form__container .tv-form__control--error {
    margin-bottom: 0; }

.tv-single-assign-form__action-container {
  width: 700px;
  margin: 0 auto 32px auto;
  justify-content: flex-end; }
  .tv-single-assign-form__action-container button {
    box-shadow: 0 2px 30px 0 rgba(153, 153, 153, 0.5);
    margin-left: 24px;
    font: 1rem "Campton", sans-serif;
    padding: 13px 16px 12px;
    font-weight: 300; }

.tv-single-assign-form__panel {
  padding: 10px 48px; }
  .tv-single-assign-form__panel--secondary {
    background: #f8f8f9;
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
    padding: 0px 48px 20px; }

.tv-single-assign-form__sub-title {
  color: #322878;
  font: 1.125rem/1.75rem "Campton", sans-serif;
  font-weight: 300;
  letter-spacing: -0.2px;
  margin: 24px 0 12px 0; }

.tv-single-assign-form__label, .tv-single-assign-form__input {
  color: #313131;
  font: 0.875rem/1.5rem "PT Serif", serif;
  line-height: 24px; }
  .tv-single-assign-form__label .tv-date-range-picker__container, .tv-single-assign-form__input .tv-date-range-picker__container {
    border: 1px solid #c5c5c5;
    border-radius: 4px;
    display: block;
    width: 100%; }
    .tv-single-assign-form__label .tv-date-range-picker__container .tv-form__date-field, .tv-single-assign-form__input .tv-date-range-picker__container .tv-form__date-field {
      font: 0.875rem "PT Serif", serif;
      padding: 16px 20px;
      color: #a9a9a9;
      position: relative; }
      .tv-single-assign-form__label .tv-date-range-picker__container .tv-form__date-field:after, .tv-single-assign-form__input .tv-date-range-picker__container .tv-form__date-field:after {
        font: 1rem;
        position: absolute;
        content: "\e929";
        font-family: "transvoice-icon";
        top: 19px;
        right: 20px; }

.tv-single-assign-form__label {
  margin: 16px 0 5px 0; }

.tv-single-assign-form__input {
  position: relative; }
  .tv-single-assign-form__input-arrow:after {
    content: "\e933";
    position: absolute;
    font: 0.7rem "transvoice-icon";
    right: 27px;
    top: 20px;
    color: #313131; }
  .tv-single-assign-form__input-sameabove {
    justify-content: flex-end;
    align-self: center;
    margin: 15px 0 0 0; }
    .tv-single-assign-form__input-sameabove .tv-toggleSwitch__wrapper {
      margin-left: 10px; }
  .tv-single-assign-form__input--my-home {
    margin-top: 15px; }

.tv-single-assign-form__control {
  border: 1px solid #C5C5C5;
  border-radius: 4px;
  width: 100%;
  font: 0.875rem "PT Serif", serif;
  outline: none;
  color: #A9A9A9;
  margin-bottom: 8px; }

.tv-single-assign-form__dropdownlist {
  padding: 14px 20px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }

.tv-single-assign-form__textfield {
  padding: 14px 20px; }
  .tv-single-assign-form__textfield-disabled {
    background-color: #f8f8f9; }

.tv-single-assign-form__textarea {
  height: 120px; }

.tv-single-assign-form__file-container {
  margin: 0;
  padding: 0;
  flex-flow: row wrap; }

.tv-single-assign-form__file-element {
  list-style: none;
  margin: 0 8px 0 0;
  width: calc((100% - 24px) / 4); }
  .tv-single-assign-form__file-element:nth-child(4n + 0) {
    margin-right: 0; }

.tv-single-assign-form__file-name {
  border: 1px solid #eaeaea;
  border-radius: 0 0 5px 5px;
  background: #f8f8f8;
  font: 0.625rem "PT Serif", serif;
  color: #322878;
  bottom: 0;
  position: absolute;
  display: flex;
  width: 100%;
  padding: 10px 0; }
  .tv-single-assign-form__file-name:before {
    content: "\e93c";
    color: #322878;
    font: 0.75rem "transvoice-icon";
    margin: 0 8px; }

.tv-single-assign-form__file-item {
  min-height: 120px;
  border-radius: 5px;
  position: relative;
  justify-content: center;
  display: flex; }
  .tv-single-assign-form__file-item:before {
    color: #FFF;
    font: 2.6rem "transvoice-icon";
    margin-top: 20px; }
  .tv-single-assign-form__file-item--pdf {
    background: #ff9ba0; }
    .tv-single-assign-form__file-item--pdf:before {
      content: "\e93b"; }
  .tv-single-assign-form__file-item--doc, .tv-single-assign-form__file-item--docx, .tv-single-assign-form__file-item--png, .tv-single-assign-form__file-item--jpg {
    background: #6478e6; }
    .tv-single-assign-form__file-item--doc:before, .tv-single-assign-form__file-item--docx:before, .tv-single-assign-form__file-item--png:before, .tv-single-assign-form__file-item--jpg:before {
      content: "\e93a"; }
  .tv-single-assign-form__file-item--other {
    background: #6478e6; }
    .tv-single-assign-form__file-item--other:before {
      content: ""; }
  .tv-single-assign-form__file-item--dragdrop {
    background: #FFF;
    border: 1px dashed #cbcbcb;
    outline: none;
    font: 0.75rem "Campton", sans-serif;
    font-weight: 300;
    text-transform: uppercase;
    color: #c5c5c5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0 20px;
    height: 120px;
    cursor: pointer; }
    .tv-single-assign-form__file-item--dragdrop:before {
      content: "\e93d";
      color: #FA0A5F;
      font: 1.4rem "transvoice-icon";
      margin-bottom: 4px; }

.tv-single-assign-form__action-container .tv-single-assign-form__button-save {
  border-color: #FFF;
  background: #FFF; }

.tv-single-assign-form__button-addSession {
  border: 1px dashed #FA0A5F;
  border-radius: 5px;
  width: 100%;
  background: #FFF;
  cursor: pointer;
  color: #FA0A5F;
  font: 1rem/1.5rem "PT Serif", serif;
  font-weight: bold;
  text-align: center;
  outline: none;
  padding: 8px 0 10px 0;
  margin-top: 16px; }

.tv-single-assign-form__button-removeSession {
  background: #FA0A5F;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  border: 0;
  display: flex;
  align-self: center;
  justify-content: center;
  cursor: pointer;
  outline: none; }
  .tv-single-assign-form__button-removeSession:before {
    content: "\e926";
    color: #fff;
    font-family: "transvoice-icon"; }

.tv-single-assign-form__button-removeFile {
  color: #FA0A5F;
  border: 0;
  background: transparent;
  display: flex;
  outline: none;
  font: 0.875rem "PT Serif", serif;
  cursor: pointer;
  margin: 10px 0; }
  .tv-single-assign-form__button-removeFile:before {
    background: #FA0A5F;
    content: "\e926";
    color: #fff;
    font: 0.45rem "transvoice-icon";
    border-radius: 50%;
    height: 16px;
    width: 16px;
    padding: 4px;
    display: inline-block;
    margin: 3px 7px 0 0; }

.tv-single-assign-form__preview-text {
  font: bold 1rem/1.5rem "PT Serif", serif;
  color: #313131; }

.tv-single-assign-form__preview-last {
  margin-bottom: 24px; }

.tv-single-assign-form__back-corner {
  position: absolute;
  font: 0.875rem/1.5rem "PT Serif", serif;
  color: #FA0A5F; }
  .tv-single-assign-form__back-corner::before {
    content: "\e934";
    font-family: "transvoice-icon";
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: "liga";
    font-size: 20px;
    padding: 0 5px 0 0; }

.tv-multiple-assign-form__title {
  color: #353535;
  font: 1.5rem/2rem "Campton", sans-serif;
  font-weight: 500;
  letter-spacing: -0.2px;
  text-align: center; }

.tv-multiple-assign-form__panel-left {
  margin-right: 37px; }

.tv-multiple-assign-form__panel-tab {
  width: 323px;
  border-radius: 5px;
  background: #FFF;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.12);
  cursor: pointer;
  padding: 20px 24px;
  margin-bottom: 18px;
  border: 1px solid #FFF; }
  .tv-multiple-assign-form__panel-tab--error {
    border: 1px solid #FA0A5F; }
  .tv-multiple-assign-form__panel-tab--selected {
    border: 1px solid #322878; }
    .tv-multiple-assign-form__panel-tab--selected:after, .tv-multiple-assign-form__panel-tab--selected:before {
      left: 100%;
      top: 32px;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none; }
    .tv-multiple-assign-form__panel-tab--selected:after {
      border-left-color: #FFF;
      border-width: 6px; }
    .tv-multiple-assign-form__panel-tab--selected:before {
      border-left-color: #322878;
      border-width: 8px;
      top: 30px; }
    .tv-multiple-assign-form__panel-tab--selected.tv-multiple-assign-form__panel-tab--error {
      border: 1px solid #FA0A5F; }
      .tv-multiple-assign-form__panel-tab--selected.tv-multiple-assign-form__panel-tab--error:before {
        border-left-color: #FA0A5F; }

.tv-multiple-assign-form__panel-title {
  color: #313131;
  font: 1.125rem "Campton", sans-serif;
  font-weight: 300;
  letter-spacing: -0.2px;
  border-bottom: 1px solid #e8ecef;
  margin: 0 0 10px 0;
  padding: 0 0 10px 0; }

.tv-multiple-assign-form__attributes {
  margin: 0;
  padding: 0; }
  .tv-multiple-assign-form__attributes li {
    list-style: none;
    margin: 5px 0;
    font: 0.875rem/1.5rem "PT Serif", serif; }
    .tv-multiple-assign-form__attributes li.level::before {
      content: "\e927";
      font-family: "transvoice-icon";
      padding: 0 5px 0 0;
      color: #A9A9A9; }
    .tv-multiple-assign-form__attributes li.category::before {
      content: "\e92c";
      font-family: "transvoice-icon";
      padding: 0 5px 0 0;
      color: #A9A9A9; }
    .tv-multiple-assign-form__attributes li.datetime::before {
      content: "\e929";
      font-family: "transvoice-icon";
      padding: 0 5px 0 0;
      color: #A9A9A9; }

.tv-multiple-assign-form__button-add-more {
  border: 1px dashed #FA0A5F;
  box-shadow: 0 2px 30px 0 rgba(153, 153, 153, 0.5);
  border-radius: 5px;
  color: #FA0A5F;
  background: #FFF;
  font: 1rem "PT Serif", serif;
  font-weight: bold;
  width: 100%;
  margin-top: 6px;
  padding: 10px 0;
  cursor: pointer; }

.tv-multiple-assign-form__button-delete {
  color: #322878;
  font: 1rem "PT Serif", serif;
  font-weight: bold;
  background: transparent;
  border: 0;
  position: absolute;
  right: 19px;
  top: 15px;
  cursor: pointer;
  display: flex;
  align-items: center; }
  .tv-multiple-assign-form__button-delete:before {
    content: "\e926";
    color: #322878;
    font: 0.5rem "transvoice-icon";
    height: 8px;
    width: 8px;
    display: inline-block;
    margin: 0 7px 0 0; }

.tv-multiple-assign-form__back-corner {
  font: 0.875rem/1.5rem "PT Serif", serif;
  color: #FA0A5F; }
  .tv-multiple-assign-form__back-corner::before {
    content: "\e934";
    font-family: "transvoice-icon";
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: "liga";
    font-size: 20px;
    padding: 0 5px 0 0; }

.tv-form__checkbox-container {
  position: relative;
  display: flex;
  align-items: center; }
  .tv-form__checkbox-container input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0; }
    .tv-form__checkbox-container input:checked ~ .tv-form__checkbox-checkmark:after {
      content: "\e939";
      font: 0.55rem "transvoice-icon";
      color: #322878;
      position: absolute;
      top: 4px;
      left: 2px; }
  .tv-form__checkbox-container--small-text {
    font-size: 0.75rem; }

.tv-form__checkbox-checkmark {
  top: 0;
  left: 0;
  height: 17px;
  width: 17px;
  margin-right: 10px;
  position: relative;
  border-radius: 2px;
  border: 1px solid #322878;
  cursor: pointer;
  padding-right: 15px; }

.tv-form__error {
  font: 0.8125rem "Campton", sans-serif;
  font-weight: 300;
  color: #FA0A5F;
  display: inline-block;
  min-width: 20px;
  line-height: 19px; }

.tv-form__date-field:after {
  content: "\e929";
  font-family: "transvoice-icon"; }

.tv-left-pannel__wrapper {
  flex: 1 1;
  max-width: 250px;
  background-color: #FFF;
  box-shadow: 0 0 13px 0 rgba(223, 223, 223, 0.5);
  z-index: 10; }

.tv-left-pannel__logo {
  width: 100%;
  height: 99px;
  padding-top: 25px;
  font-family: "PT Serif", serif;
  letter-spacing: -1px;
  display: flex;
  justify-content: center;
  cursor: pointer; }
  .tv-left-pannel__logo a {
    text-decoration: none; }
  .tv-left-pannel__logo img {
    width: 120px; }

.tv-left-pannel__sidebar a {
  text-decoration: none; }

.tv-left-pannel__sidebar .tv-icon-fixed-width {
  display: inline-block;
  width: 24px;
  text-align: center; }

.tv-left-pannel__sidebar-item {
  display: flex;
  padding: 20px 10px 20px 20px;
  cursor: pointer;
  color: #313131;
  font: 0.875rem/1.5rem "Campton", sans-serif;
  font-weight: 100;
  border-left: 2px solid transparent; }
  .tv-left-pannel__sidebar-item:hover, .tv-left-pannel__sidebar-item.active {
    background-color: #FAFAFA;
    border-left: 2px solid #FA0A5F;
    font-weight: bold;
    color: #FA0A5F; }
    .tv-left-pannel__sidebar-item:hover i:before, .tv-left-pannel__sidebar-item.active i:before {
      color: #FA0A5F; }

.tv-left-pannel__sidebar-icon {
  font-size: 21px;
  margin-right: 10px; }

.tv-right-pannel-wrapper {
  flex: 4.76 1;
  min-height: 100vh;
  background-color: #FAFAFA;
  overflow-y: scroll; }
  .tv-right-pannel-wrapper .tv-right-status {
    width: 100%;
    height: 64px;
    background-color: #FFF;
    padding: 14px 98px 14px 40px; }
    .tv-right-pannel-wrapper .tv-right-status .tv-user-info {
      display: flex;
      align-items: center; }
      .tv-right-pannel-wrapper .tv-right-status .tv-user-info .tv-avatar {
        display: inline;
        position: relative;
        cursor: pointer; }
        .tv-right-pannel-wrapper .tv-right-status .tv-user-info .tv-avatar__img {
          border-radius: 50%;
          width: 36px;
          height: 36px;
          object-fit: cover; }
      .tv-right-pannel-wrapper .tv-right-status .tv-user-info .tv-notification {
        display: inline;
        margin-left: 30px;
        position: relative;
        cursor: pointer; }
        .tv-right-pannel-wrapper .tv-right-status .tv-user-info .tv-notification__icon {
          vertical-align: middle;
          font-size: 1.4rem;
          color: #c5c5c5; }
        .tv-right-pannel-wrapper .tv-right-status .tv-user-info .tv-notification__circle {
          width: 11px;
          height: 11px;
          border-radius: 50%;
          background-color: #FA0A5F;
          display: inline-block;
          position: absolute;
          top: -2px;
          left: 10px; }
      .tv-right-pannel-wrapper .tv-right-status .tv-user-info .tv-search {
        margin-left: auto; }
        .tv-right-pannel-wrapper .tv-right-status .tv-user-info .tv-search .form-inline {
          display: flex;
          align-items: center; }
          .tv-right-pannel-wrapper .tv-right-status .tv-user-info .tv-search .form-inline i {
            margin-right: 15px;
            font-size: 20px;
            cursor: pointer; }
          .tv-right-pannel-wrapper .tv-right-status .tv-user-info .tv-search .form-inline input {
            width: 16rem;
            border: none;
            font-family: "PT Serif";
            font-size: 1rem;
            color: #313131; }
  .tv-right-pannel-wrapper .tv-right-content {
    padding-top: 0;
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 60px;
    margin-left: auto;
    margin-right: auto; }

.tv-bg-login-page {
  background-color: #FFF; }

.tv-login-page__wrapper {
  margin: 0 auto;
  max-width: 1440px; }

.tv-login-page__left-wrapper {
  flex: 1 1;
  height: 100vh;
  background-image: url("/images/interpreter-login-image.jpg");
  background-size: cover;
  background-position-y: center;
  color: #FFF;
  flex-direction: column; }

.tv-login-page__left-wrapper.tv-bg-login-client-page {
  background-image: url("/images/client-login-image.png"); }

.tv-login-page__left-logo-wrapper {
  height: 52px;
  display: inline-block;
  padding: 153px 64px;
  position: relative; }

.tv-login-page__left-logo-icon {
  display: inline-block;
  height: 42px;
  position: relative;
  top: 3px; }

.tv-login-page__left-logo-text {
  font: 500 2.5rem "Campton", sans-serif;
  line-height: 2; }

.tv-login-page__left-footer-wrapper {
  margin: auto auto 26px auto; }

.tv-login-page__left-footer-text {
  font: 0.875rem/1.5rem "PT Serif", serif; }
  .tv-login-page__left-footer-text span {
    margin-left: 20px;
    margin-right: 20px; }

.tv-login-page__right-wrapper {
  background-color: #FFF;
  flex: 1.4 1;
  justify-content: center;
  align-items: center; }
  .tv-login-page__right-wrapper .tab-panel-content {
    padding: 52px;
    max-height: 100vh;
    overflow-y: scroll; }
    .tv-login-page__right-wrapper .tab-panel-content::-webkit-scrollbar {
      width: 8px; }
      .tv-login-page__right-wrapper .tab-panel-content::-webkit-scrollbar-track {
        background: transparent; }
      .tv-login-page__right-wrapper .tab-panel-content::-webkit-scrollbar-thumb {
        background: #f1f1f1;
        border-radius: 4px; }
        .tv-login-page__right-wrapper .tab-panel-content::-webkit-scrollbar-thumb:hover {
          background: #A9A9A9; }
  .tv-login-page__right-wrapper-wrapper.tv-bg-login-report-page {
    background-image: url("/images/interpreter-login-image.jpg"); }

.tv-login-page__right-footer-wrapper {
  position: absolute;
  bottom: 0;
  margin: auto auto 26px auto; }

.tv-login-page__right-footer-text {
  font: 0.875rem/1.5rem "PT Serif", serif; }
  .tv-login-page__right-footer-text span {
    margin-left: 20px;
    margin-right: 20px; }

.tv-login-page__landing-right-wrapper {
  flex: 1 1;
  height: 100vh;
  background-size: cover;
  background-position-y: center;
  color: #FFF;
  flex-direction: column; }

.tv-login-page__landing-right-wrapper.tv-bg-login-report-page {
  background-image: url("/images/interpreter-login-image.jpg"); }

.tv-login-page__landing-right-logo-wrapper {
  height: 52px;
  display: inline-block;
  padding: 153px 64px;
  position: relative; }

.tv-login-page__landing-right-logo-icon {
  display: inline-block;
  height: 42px;
  position: relative;
  top: 3px; }

.tv-login-page__landing-right-logo-text {
  font: 500 2.5rem "Campton", sans-serif;
  line-height: 2; }

.tv-login__wrapper {
  width: 400px;
  flex-flow: column;
  align-items: center; }

.tv-login__title {
  font: 3rem/3.375rem "Campton", sans-serif;
  color: #313131;
  font-weight: 500;
  letter-spacing: -0.5px; }

.tv-login__description {
  padding: 10px 30px 10px 30px;
  font: 0.875rem/1.5rem "PT Serif", serif;
  text-align: center;
  color: #313131; }

.tv-login__form-wrapper {
  width: 100%;
  border-radius: 3px;
  box-shadow: 0 10px 37px 0 rgba(0, 0, 0, 0.12);
  padding: 36px 30px 41px 30px;
  margin-top: 16px; }

.tv-login__form-label {
  font: 1.25rem/1.8125rem "Campton", sans-serif;
  color: #313131; }

.tv-login__form-email-input {
  width: 100%; }

.tv-login__form-interpreter-id-label {
  margin-top: 15px; }

.tv-login__form-interpreterId-input {
  width: 100%; }

.tv-login__form-submit.tv-buttons__element {
  width: 100%;
  margin-top: 26px;
  padding: 13px 0px 13px 0px;
  font-weight: 300;
  line-height: 1.4375rem; }

.tv-buttons--primary {
  background-color: #FA0A5F;
  color: #FFF8F2;
  border: 1px solid #FA0A5F; }

.tv-buttons--secondary {
  color: #322878;
  background: #fff;
  border: 1px solid #fff; }

.tv-login__landing {
  width: 50%;
  text-decoration: none;
  margin-top: 56px;
  padding: 16px;
  font-weight: 300;
  line-height: 1.4375rem;
  font-size: 22px; }

.tv-buttons--link {
  text-decoration: none !important; }

.tv-workassignment__wrapper {
  margin: 50px 0 0 0; }

.tv-workassignment__title, .tv-youravailability__title {
  color: #353535;
  font-weight: 500;
  font: 1.5rem/2rem "Campton", sans-serif;
  margin: 0 0 20px 0; }

.tv-workassignment__control-panel {
  margin: 0 0 20px 0; }
  .tv-workassignment__control-panel.h-pdl--30 {
    padding-left: 30px; }
  .tv-workassignment__control-panel .h-fs--16 {
    font-size: 16px; }

.tv-workassignment__list-item {
  cursor: pointer;
  margin: 16px 0 0 0;
  padding: 18px 0;
  font-family: "PT Serif", serif;
  z-index: 10; }
  .tv-workassignment__list-item-child {
    margin: 0 13px;
    box-shadow: inset 0 10px 18px -12px rgba(58, 68, 131, 0.2), 0 10px 18px -12px rgba(58, 68, 131, 0.2); }

.tv-workassignment__item-status {
  width: 16.6%;
  align-items: center;
  justify-content: center; }

.tv-workassignment__item-order {
  width: 10%; }

.tv-workassignment__item-name {
  width: 29.1%; }

.tv-workassignment__item-category {
  width: 16.7%; }

.tv-workassignment__item-datetime {
  width: 27.6%; }

.tv-workassignment__item-type {
  width: 12%; }

.tv-workassignment__item-menu {
  width: 7%;
  align-items: center;
  justify-content: center; }
  .tv-workassignment__item-menu-button:before {
    font-family: "transvoice-icon";
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: "liga";
    font-size: 1.5rem; }

.tv-workassignment__item-label {
  font-size: 0.875rem;
  color: #A9A9A9;
  margin: 0 0 11px 0; }

.tv-workassignment__item-text {
  color: #313131; }
  .tv-workassignment__item-text-available-until {
    font-weight: bold;
    color: #322878; }

.tv-workassignment__status {
  font: 0.625rem "Campton", sans-serif;
  color: #FFF;
  border-radius: 20px;
  text-transform: uppercase;
  padding: 4px 10px 3px 10px; }
  .tv-workassignment__status--available {
    background: #ff0000; }
  .tv-workassignment__status--accepted {
    background: #0000ff; }
  .tv-workassignment__status--rejected {
    background: #343a40; }
  .tv-workassignment__status--losted {
    background: #696969; }
  .tv-workassignment__status--traveling, .tv-workassignment__status--working {
    background: #FBBCDB;
    color: black; }
  .tv-workassignment__status--fullfilled {
    background: #fec00d;
    color: black; }
  .tv-workassignment__status--done {
    background: #ffa500;
    color: black; }
  .tv-workassignment__status--cancelled {
    background: #000000; }
  .tv-workassignment__status--submitted {
    background: #008000; }
  .tv-workassignment__status--accepted:before, .tv-workassignment__status--submitted:before {
    content: "\e939";
    font-family: "transvoice-icon";
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: "liga";
    color: #FFF;
    margin: 0 6px 0 0; }

.tv-workassignment__detail-wrapper {
  font-family: "PT Serif", serif; }

.tv-workassignment__detail-status {
  font-size: 0.875rem;
  color: #A9A9A9;
  margin: 36px 0 24px 32px; }
  .tv-workassignment__detail-status .tv-workassignment__status {
    margin-right: 8px; }

.tv-workassignment__detail-orderno {
  color: #454c98; }

.tv-workassignment__detail-name {
  font: 1.5rem/2.1875rem "Campton", sans-serif;
  font-weight: 300;
  color: #A9A9A9;
  color: #353535;
  margin: 0 32px; }

.tv-workassignment__detail-datetime {
  font-size: 0.875rem;
  color: #322878;
  margin: 6px 0 24px 32px; }
  .tv-workassignment__detail-datetime .icon-calendar {
    margin-right: 14px; }

.tv-workassignment__detail-title {
  font: 1rem "Campton", sans-serif;
  color: #313131;
  background-color: #f9f9f9;
  padding: 14px 0 13px 32px; }

.tv-workassignment__detail-description {
  margin: 16px 32px 0px 32px;
  font: 0.875rem/1.5rem "PT Serif", serif;
  color: #313131; }

.tv-workassignment__detail-buttons {
  margin: 36px 32px;
  justify-content: space-between; }
  .tv-workassignment__detail-buttons .tv-buttons__element {
    padding: 13px 0 12px;
    width: 47.04%; }
    .tv-workassignment__detail-buttons .tv-buttons__element-link {
      width: 47.04%; }
      .tv-workassignment__detail-buttons .tv-buttons__element-link button {
        width: 100%; }
    .tv-workassignment__detail-buttons .tv-buttons__element--report {
      padding: 13px 24px 12px;
      width: unset; }

.tv-workassignment__attach-title {
  padding: 14px 0 13px 32px;
  color: #a9a9a9;
  font-family: "PT Serif";
  font-size: 14px;
  letter-spacing: 0.3px;
  line-height: 20px; }

.tv-workassignment__attach-list {
  padding: 0px 0 17px 32px; }
  .tv-workassignment__attach-list .tv-attachment {
    margin-right: 8px;
    margin-bottom: 8px;
    background-color: transparent; }

.tv-workassignment__info-wrapper {
  margin: 17px 0 17px 32px;
  padding: 0; }

.tv-workassignment__info-item {
  list-style: none;
  margin: 7px 0;
  font-size: 0.875rem;
  color: #313131; }
  .tv-workassignment__info-item:before {
    margin-right: 15px; }
  .tv-workassignment__info-item a {
    color: #322878; }
  .tv-workassignment__info-item.icon-none-icon:before {
    content: "";
    margin-right: 30px; }

.tv-workassignment__info-agree {
  margin: 17px 32px 0 32px;
  color: #313131;
  font: italic 0.875rem/1.5rem "PT Serif", serif; }
  .tv-workassignment__info-agree-terms {
    cursor: pointer;
    text-decoration: underline; }

.tv-workassignment__drawer-overlay {
  content: "";
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 16; }

.tv-workassignment__drawer-holder {
  width: 468px;
  background: #FFF;
  position: fixed;
  height: 100vh;
  overflow-y: auto;
  top: 0;
  right: 0;
  z-index: 20;
  box-shadow: -5px 0 15px 0 rgba(137, 136, 150, 0.27);
  transition: all 0.5s ease;
  transform: translateX(100%); }
  .js-slide-in .tv-workassignment__drawer-holder {
    transform: translateX(0%); }

.tv-workassignment__drawer-close {
  position: absolute;
  top: 10px;
  right: 10px; }
  .tv-workassignment__drawer-close:before {
    color: #313131;
    font-size: 1.2rem; }

.tv-workassignment__drawer2-overlay2 {
  content: "";
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 5; }

.tv-workassignment__drawer2-holder2 {
  width: 560px;
  background: #FFF;
  position: fixed;
  height: 100vh;
  overflow-y: auto;
  top: 0;
  right: 0;
  z-index: 15;
  box-shadow: -5px 0 15px 0 rgba(137, 136, 150, 0.27);
  transition: all 0.5s ease;
  transform: translateX(100%); }
  .js-slide-in2 .tv-workassignment__drawer2-holder2 {
    transform: translateX(0%); }

.tv-workassignment__drawer2-close2 {
  position: absolute;
  top: 10px;
  right: 10px; }
  .tv-workassignment__drawer2-close2:before {
    color: #313131;
    font-size: 1.2rem; }

.tv-workassignment__filter-date-range {
  padding-right: 30px;
  position: relative; }
  .tv-workassignment__filter-date-range__content {
    position: absolute;
    box-shadow: 1px 1px 1px 1px #C5C5C5;
    background: #FFF;
    display: flex;
    flex-direction: column; }
    .tv-workassignment__filter-date-range__content .tv-buttons__element {
      width: 30%;
      margin: 20px auto; }

.tk-calendar__wrapper {
  background: #FFF;
  border-radius: 5px;
  box-shadow: 0 10px 18px -12px rgba(58, 68, 131, 0.2); }

.tk-calendar__header {
  text-align: center; }

.tk-calendar__title {
  display: inline-block;
  color: #FA0A5F;
  font: 1rem/1.5rem "PT Serif", serif;
  font-weight: bold;
  margin: 13px 0; }
  .tk-calendar__title .icon-calendar:before {
    margin-right: 8px; }

.tk-calendar__button {
  padding: 0;
  outline: none;
  cursor: pointer;
  min-height: 30px; }
  .tk-calendar__button:before {
    font-family: "transvoice-icon";
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: "liga";
    color: #bdbdbd;
    cursor: pointer; }
  .tk-calendar__button--previous:before {
    content: "\e932";
    margin: 0 30px; }
  .tk-calendar__button--next:before {
    content: "\e931";
    margin: 0 30px; }

.tk-calendar__tabs-wrapper {
  margin: 0;
  padding: 0;
  display: inline-flex;
  position: absolute;
  right: 15px;
  top: 10px; }
  .tk-calendar__tabs-wrapper > li {
    list-style: none; }

.tk-calendar__tabs-button {
  border: 0;
  background: transparent;
  color: #C5C5C5;
  font: 0.875rem/1.125rem "PT Serif", serif;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  padding: 0 0 0 9px; }
  .tk-calendar__tabs-button.selected {
    pointer-events: none;
    cursor: default;
    color: #FA0A5F; }

.tk-dialog__wrapper {
  position: absolute;
  min-height: 150px;
  width: 387px;
  left: calc(100% + 10px);
  top: -2px;
  box-shadow: 0 0 18px 0 rgba(197, 197, 197, 0.65);
  border-radius: 5px;
  z-index: 2;
  padding: 23px 35px;
  background: #FFF;
  cursor: default; }
  .tk-dialog__wrapper--before {
    right: 100%;
    top: 10px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-right-color: #FFF;
    border-width: 5px; }

.tk-dialog__btn-close {
  top: 8px;
  right: 10px;
  position: absolute; }
  .tk-dialog__btn-close:before {
    content: "\e926";
    color: #000;
    font-size: 0.6rem; }

.tv-youravailability__wrapper {
  margin-top: 32px; }

.tv-youravailability__list {
  flex-wrap: wrap; }
  .tv-youravailability__list-drawer {
    margin-top: 10%;
    margin-left: 2%; }
  .tv-youravailability__list-item {
    position: relative;
    cursor: default;
    margin: 0 1.5% 16px 0;
    font-family: "PT Serif", serif;
    width: 31%;
    box-shadow: 0 0 13px 0 rgba(223, 223, 223, 0.5); }
    .tv-youravailability__list-item-drawer {
      position: relative;
      cursor: default;
      margin: 0 1.5% 16px 0;
      font-family: "PT Serif", serif;
      width: 90%;
      box-shadow: 0 0 13px 0 rgba(223, 223, 223, 0.5); }

.tv-youravailability__container {
  padding: 24px;
  width: 100%; }

.tv-youravailability__item-hr {
  border: 0.5px solid rgba(223, 223, 223, 0.5); }

.tv-youravailability__item-space {
  padding-left: 5px; }

.tv-youravailability__item-label {
  color: #A9A9A9;
  font: 0.875rem/1.5rem;
  letter-spacing: 0.3; }

.tv-youravailability__item-text {
  color: #313131;
  font: 0.875rem/1.5rem "PT Serif", serif;
  letter-spacing: 0.3; }
  .tv-youravailability__item-text-datetime {
    color: #322878;
    font: 300 1.125rem/1.75rem "Campton", sans-serif;
    letter-spacing: -0.2px;
    min-height: 56px; }

.tv-youravailability__item-menu {
  cursor: pointer;
  padding-top: 19px;
  position: absolute;
  top: 0;
  right: 10px; }
  .tv-youravailability__item-menu-button:before {
    font-family: "transvoice-icon";
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: "liga";
    font-size: 1.5rem; }

.tv-youravailability__dropdown .tv-filter-list__menu {
  width: 90px;
  margin: 0;
  right: 0;
  box-shadow: 0 0 13px 0 rgba(223, 223, 223, 0.5); }

.tv-youravailability__dropdown .tv-buttons__type {
  padding: 10px;
  text-align: left; }

.dashboard__filter-holder {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
  margin-top: 15px;
  border-radius: 15px;
  border: 1px solid #efefef;
  background-color: white;
  padding: 20px; }

.dashboard__chart-holder {
  margin-top: 15px;
  padding: 10px;
  display: grid;
  display: -ms-grid;
  background-color: rgba(255, 255, 255, 0.212);
  color: #716464;
  border-radius: 15px;
  font-family: "Campton", sans-serif;
  border: 1px solid #ababab;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.031), 0 3px 6px rgba(0, 0, 0, 0); }

.dashboard__display-card {
  padding: 10px 5px;
  display: flow-root;
  width: 100%;
  height: auto;
  border: 1px solid #efefef;
  background-color: white; }
  .dashboard__display-card-header {
    display: block;
    width: 100%;
    font-size: 1.2rem;
    padding: 15px; }
  .dashboard__display-card_nodata {
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    padding-top: 50px;
    width: 100%;
    height: 100%;
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    background-color: rgba(255, 255, 255, 0.65); }

.dashboard__samll-display-card {
  padding: 10px 5px;
  display: grid;
  height: auto;
  width: 100%;
  align-items: center;
  border: 1px solid #efefef;
  background-color: white; }
  .dashboard__samll-display-card:nth-child(1) {
    -ms-grid-column: 1;
    grid-column: 1;
    -ms-grid-row: 1;
    grid-row: 1; }
  .dashboard__samll-display-card:nth-child(2) {
    -ms-grid-column: 2;
    grid-column: 2;
    -ms-grid-row: 1;
    grid-row: 1; }
  .dashboard__samll-display-card-header {
    display: block;
    width: 100%;
    font-size: 1.2rem;
    padding: 15px; }
    .dashboard__samll-display-card-header span {
      width: 70%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }

.dashboard_samll-detail-card {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: auto auto;
  grid-template-columns: auto auto; }
  .dashboard_samll-detail-card-header {
    display: block;
    width: 100%;
    font-size: 1rem;
    padding: 15px; }
    .dashboard_samll-detail-card-header span {
      width: 190px;
      white-space: normal;
      white-space: initial;
      overflow: hidden;
      text-overflow: ellipsis; }
  .dashboard_samll-detail-card-content {
    display: grid;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    max-height: 2rem;
    font-weight: 100;
    float: left;
    background-color: white;
    color: #716464;
    font-size: 1rem;
    padding: 15px; }

.report-card-header {
  font-family: "Campton", sans-serif;
  font-size: 1rem; }

.round-border {
  border-radius: 10px; }

.p-1 {
  padding: 5px; }

.auto-row-1 {
  display: grid;
  display: -ms-grid;
  grid-template-rows: auto;
  -ms-grid-rows: minmax(min-content, 1fr); }

.auto-row-2 {
  display: grid;
  display: -ms-grid;
  grid-template-rows: auto auto;
  -ms-grid-rows: minmax(min-content, 1fr) 2px minmax(min-content, 1fr); }

.auto-col-1 {
  display: grid;
  display: -ms-grid;
  grid-template-columns: auto;
  grid-row-gap: 15px; }

.auto-col-2 {
  display: grid;
  display: -ms-grid;
  grid-template-columns: auto auto;
  -ms-grid-columns: minmax(min-content, 1fr) 2px minmax(min-content, 1fr);
  grid-gap: 20px; }

.exact-col-4 {
  display: grid;
  display: -ms-grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-columns: 1fr 10px 1fr 10px 1fr 10px 1fr;
  grid-gap: 10px; }

.exact-col-2 {
  display: grid;
  display: -ms-grid;
  grid-template-columns: 1fr 1fr;
  -ms-grid-columns: 1fr 20px 1fr;
  grid-gap: 20px; }

.exact-col-1 {
  display: grid;
  display: -ms-grid;
  grid-template-columns: 1fr;
  -ms-grid-columns: 1fr;
  grid-gap: 20px; }

.popup-content {
  padding: 30px;
  color: #787878;
  background-color: #fcf7f8;
  border: 1px solid rgba(0, 0, 0, 0.05); }

.float-filter {
  display: grid;
  display: -ms-grid;
  grid-template-columns: auto auto;
  -ms-grid-columns: minmax(min-content, 1fr) 2px minmax(min-content, 1fr);
  position: fixed;
  z-index: 1000000;
  bottom: 35%;
  right: 8%; }
  .float-filter-icon-box {
    background-color: #322878;
    border-radius: 10px 10px 0px 10px;
    width: 60px;
    color: white;
    height: 60px;
    box-shadow: 0 3px 6px #0000001a, 0 3px 6px #00000078, 0 0px 0px #0000001a, 0 3px 6px #0000001a; }
    .float-filter-icon-box span {
      font-size: 2rem;
      padding: 10px; }
  .float-filter-content {
    background-color: #322878;
    color: white;
    align-self: end;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 5px;
    height: 40px;
    width: 100%;
    box-shadow: 0 3px 6px #0000001a, 0 3px 6px #00000078, 0 3px 6px #0000001a, 0 0px 0px #0000001a; }
    .float-filter-content span {
      float: left;
      padding: 5px; }
  .float-filter-clear-btn {
    width: auto;
    border-left: 3px white solid;
    height: auto;
    cursor: pointer;
    font-size: 1.4rem; }

.text-float-right {
  padding-right: 5% !important;
  max-width: 200px !important;
  text-align: right !important; }

.report___sidemenu-scroll {
  height: 200px !important;
  width: inherit !important; }

