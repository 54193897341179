.tv-bg-login-page {
  background-color: $WHITE_COLOR;
}

.tv-login-page {
  &__wrapper {
    margin: 0 auto;
    max-width: 1440px;
  }

  &__left {
    &-wrapper {
      flex: 1;
      height: 100vh;
      background-image: url("/images/interpreter-login-image.jpg");
      background-size: cover;
      background-position-y: center;
      color: $WHITE_COLOR;
      flex-direction: column;
    }

    &-wrapper.tv-bg-login-client-page {
      background-image: url("/images/client-login-image.png");
    }
    
    &-logo {
      &-wrapper {
        height: 52px;
        display: inline-block;
        // margin: 153px auto 0 auto;
        padding: 153px 64px;
        position: relative;
      }
      &-icon {
        display: inline-block;
        height: 42px;
        position: relative;
        top: 3px;
      }
      &-text {
        font: 500 2.5rem $PRIMARY_FONT;
        // margin-left: 10px;
        line-height: 2;
      }
    }

    &-footer {
      &-wrapper {
        margin: auto auto 26px auto;
      }
      &-text {
        font: 0.875rem/1.5rem $SECONDARY_FONT;
        span {
          margin-left: 20px;
          margin-right: 20px;
        }
      }
    }
  }

  &__right {
    &-wrapper {
      background-color: $WHITE_COLOR;
      flex: 1.4;
      justify-content: center;
      align-items: center;

      .tab-panel-content {
        padding: 52px;
        @include scroll-bar(100vh);
      }
      &-wrapper.tv-bg-login-report-page {
        background-image: url("/images/interpreter-login-image.jpg");
      }

    }

    &-footer {
      &-wrapper {
        position: absolute;
        bottom: 0;
        margin: auto auto 26px auto;
      }
      &-text {
        font: 0.875rem/1.5rem $SECONDARY_FONT;
        span {
          margin-left: 20px;
          margin-right: 20px;
        }
      }
    }
  }
  &__landing-right{
    &-wrapper {
      flex: 1;
      height: 100vh;
     
      background-size: cover;
      background-position-y: center;
      color: $WHITE_COLOR;
      flex-direction: column;
    }

    &-wrapper.tv-bg-login-report-page {
      background-image: url("/images/interpreter-login-image.jpg");
    }
    
    &-logo {
      &-wrapper {
        height: 52px;
        display: inline-block;
        // margin: 153px auto 0 auto;
        padding: 153px 64px;
        position: relative;
      }
      &-icon {
        display: inline-block;
        height: 42px;
        position: relative;
        top: 3px;
      }
      &-text {
        font: 500 2.5rem $PRIMARY_FONT;
        // margin-left: 10px;
        line-height: 2;
      }
    }

  }
}

.tv-login {
  &__wrapper {
    width: 400px;
    flex-flow: column;
    align-items: center;
  }

  &__title {
    font: 3rem/3.375rem $PRIMARY_FONT;
    color: $NAV_BAR_TEXT_COLOR;
    font-weight: 500;
    letter-spacing: -0.5px;
  }

  &__description {
    padding: 10px 30px 10px 30px;
    font: 0.875rem/1.5rem $SECONDARY_FONT;
    text-align: center;
    color: $NAV_BAR_TEXT_COLOR;
  }

  &__form {
    &-wrapper {
      width: 100%;
      border-radius: 3px;
      box-shadow: 0 10px 37px 0 rgba(0, 0, 0, 0.12);
      padding: 36px 30px 41px 30px;
      margin-top: 16px;
    }
    &-label {
      font: 1.25rem/1.8125rem $PRIMARY_FONT;
      color: $NAV_BAR_TEXT_COLOR;
    }
    &-email-input {
      width: 100%;
    }
    &-interpreter-id-label {
      margin-top: 15px;
    }
    &-interpreterId-input {
      width: 100%;
    }
    &-submit {
      &.tv-buttons__element {
        width: 100%;
        margin-top: 26px;
        padding: 13px 0px 13px 0px;
        font-weight: 300;
        line-height: 1.4375rem;
      }
    }
  }
}

.tv-buttons--primary {
  background-color: #FA0A5F;
  color: #FFF8F2;
  border: 1px solid #FA0A5F;
}

.tv-buttons--secondary {
  color: #322878;
  background: #fff;
  border: 1px solid #fff;
}

.tv-login__landing {
  width: 50%;
  text-decoration: none;
  margin-top: 56px;
  padding: 16px;
  font-weight: 300;
  line-height: 1.4375rem;
  font-size: 22px;
}
.tv-buttons--link{
  text-decoration: none !important;
}