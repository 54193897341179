@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.form_textarea {
  position: relative;
  padding-top: 20px;

  &__form-control {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    resize: none;
    color: $DARK_GRAY_COLOR;
    background-clip: padding-box;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    font: rem-calc(14)/rem-calc(20)	$SECONDARY_FONT;
    border: 1px solid #DFE7EA;
    border-radius: 5px;
    background-color: $WHITE_COLOR;
    box-shadow: 0 3px 10px 0 rgba(0,0,0,0.04);
  }
  &__description{
    position: absolute;
    bottom: 24px;
    right: 24px;
    color: $DARK_GRAY_COLOR;
    font: rem-calc(14)/rem-calc(20)	$SECONDARY_FONT;
    letter-spacing: 0.3px;
  }
}